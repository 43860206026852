import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import { useNavigate } from 'react-router-dom';

import ButtonPrimary from './modules/primitives/ButtonPrimary';
import ButtonSecondary from './modules/primitives/ButtonSecondary';

import './Register.css';

const Register = () => {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [registrationCode, setRegistrationCode] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
    const { register } = useAuth();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setError("Passwords do not match");
            return;
        }

        try {
            await register(email, password, registrationCode, firstName, lastName);
            navigate('/'); // Redirect to home page after registration... or any other page you'd like
        } catch (error) {
            setError(error.response.data);
            console.error(error);
        }
    };

    return (
        <div className="login">
            <div className="login-container">
                <div className='login-hero'>
                    <img src="/icons/Cognisell-White.svg" alt="Cognisell Logo"/>
                </div>
                <form onSubmit={handleSubmit} className='login-form'>
                    <div className='login-form-header'>
                        <h2 className='login-form-header-text'>Register</h2>
                        <p>Create your account below</p>
                    </div>          
                    {error && <p>{error}</p>}
                    <div className='register-name-info'>
                        <input
                            type="text"
                            placeholder='First Name'
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder='Last Name'
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                        />
                    </div>
                    <input
                        type="text"
                        placeholder='Email'
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    /> 
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                    />
                    <input
                        type="text"
                        placeholder='Registration Code'
                        value={registrationCode}
                        onChange={e => setRegistrationCode(e.target.value)}
                        required
                    />
                    <div className='login-form-buttons'>
                        <ButtonPrimary children="Register" type="submit"/>
                    </div>
                    <div className='no-account'>
                        <p>Already have an account?</p>
                        <Link to='/login' style={{fontSize:'0.8rem'}}>Login here</Link>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Register;

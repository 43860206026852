import React from 'react';
import PropTypes from 'prop-types';
import './ButtonSecondary.css';

const ButtonSecondary = ({ children, onClick, disabled }) => {
  return (
    <button className="button-secondary" onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};

ButtonSecondary.propTypes = {
  //children: PropTypes.node.isRequired,
  onClick: PropTypes.func
};

export default ButtonSecondary;

import React, { useState, useEffect } from 'react';
import Evaluation from '../modules/containers/Evaluation';
import './CallRecordModal.css';

const CallRecordModal = ({ user, callRecord, onClose }) => {
    return (
        <div className='call-record-modal'>
            <div className='header-wrapper'>
                <div>
                    <button className="close-button" onClick={onClose}>x</button>
                </div>
            </div>
            <div className="body">
                <Evaluation callRecord={callRecord} />
            </div>         
        </div>
    )
};

export default CallRecordModal;
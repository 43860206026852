import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { api, authApi } from '../../../api';

const AVATAR_SIZES = {
  small: '36px',
  medium: '2.25rem',
  large: '72px',
  xlarge: '150px',
  xxlarge: '172px'
};

const DEFAULT_IMAGE = '/icons/Avatar.svg';

const Avatar = ({ size = 'medium', src, requiresAuth = false, defaultImage = '', alt = '' }) => {
  const [currentSrc, setCurrentSrc] = useState(DEFAULT_IMAGE);

  useEffect(() => {
    // Function to fetch image with authentication
    const fetchImage = async () => {
      try {
        let response;
        if (requiresAuth) {
          response = await authApi.get(src, { responseType: 'blob'});
        } else {
          response = await api.get(src, { responseType: 'blob'});
        }

        // Create a local URL for the image blob
        const localUrl = URL.createObjectURL(response.data);
        setCurrentSrc(localUrl);
      } catch (error) {
        console.error('Error fetching avatar image', error);
        setCurrentSrc(defaultImage ? defaultImage : DEFAULT_IMAGE);
      }
    };

    if (src && src !== DEFAULT_IMAGE) {
      fetchImage();
    }
  }, [src]);

  return (
    <img 
      src={currentSrc} 
      alt={alt}
      onError={() => setCurrentSrc(DEFAULT_IMAGE)}
      style={{
        width: AVATAR_SIZES[size],
        height: AVATAR_SIZES[size],
        borderRadius: '50%'
      }}
    />
  );
};

Avatar.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge', 'xxlarge']),
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

export default Avatar;

class Group {
    constructor(orgId, id, name, description, createdBy, createdOn, lastUpdatedBy, lastUpdatedOn) {
        this.orgId = orgId;
        this.id = id;
        this.name = name;
        this.description = description;
        this.createdBy = createdBy;
        this.CreatedOn = createdOn;
        this.lastUpdatedBy = lastUpdatedBy;
        this.lastUpdatedOn = lastUpdatedOn;
    }
}

export default Group;
///
// authService.js handles login, registration, and checking whether a user is logged in
///

import { api, authApi } from '../api';
import * as tokenService from './tokenService';

export async function login(email, password) {
    const response = await api.post('/api/users/login', { email, password });
    const token = response.data.token;
    tokenService.setToken(token);
    setAuthHeaders(token);
}

export async function register(email, password, registerCode, firstName, lastName) {
    const response = await api.post('/api/users', { email, password, registerCode, firstName, lastName });
    const token = response.data.token;
    tokenService.setToken(token);
    setAuthHeaders(token);
}

export async function requestPasswordReset(email) {
    return await api.post('/api/users/request-password-reset', { email });
}

export async function resetPassword(email, token, newPassword) {
    return await api.patch('/api/users/password-reset', { email, token, newPassword });
}

export function logout() {
    tokenService.removeToken();
    setAuthHeaders(null);
}

export function isLoggedIn() {
    return !!tokenService.getToken();
}

// Call this function in the top level of your app
export function initialize() {
    setAuthHeaders(tokenService.getToken());
}

function setAuthHeaders(token) {
    if (token) {
        authApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        localStorage.setItem('authToken', token);
    } else {
        delete authApi.defaults.headers.common['Authorization'];
        localStorage.removeItem('authToken');
    }
}
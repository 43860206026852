import React from "react";
import Avatar from "../primitives/Avatar";

import './ProductRecord.css';

const ProductRecord = ({name, description, onClick}) => {
    return (<div className='product-record' onClick={onClick}>
        <div className='product-record-left-content'>
            <div>
                <Avatar src='/nonexistent' defaultImage='/icons/Cognisell-Black.svg' size='medium'/>
            </div>
            <div>
                <h4 className='main'>{name}</h4>
                <p className='sub'>{description}</p>
            </div>
        </div>
        <div className='product-record-right-content'> 

        </div>
    </div>)
}

export default ProductRecord;
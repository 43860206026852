import React, { useState, useEffect } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { Flex, Group, Modal, Popover, Stack, Text } from '@mantine/core';

import ScenarioService from '../../../services/scenarioService';

import ButtonPrimary from '../primitives/ButtonPrimary';
import ButtonSecondary from '../primitives/ButtonSecondary';

import ScenarioRecord from '../containers/ScenarioRecord';

import ScenarioModal from '../../beta/ScenarioModal';
import CallRecordModal from '../../beta/CallRecordModal';
import ScenarioCreation from '../containers/ScenarioCreation';
import ProductCreation from '../containers/ProductCreation';
import ProspectCreation from '../containers/ProspectCreation';

import './Scenarios.css'



const Scenarios = ({ user, orgId }) => {
    const [scenarios, setScenarios] = useState(null);
    const [selectedScenario, setSelectedScenario] = useState(null);
    const [selectedCallRecord, setSelectedCallRecord] = useState(null);
    const [createScenario, setCreateScenario] = useState(false);
    const [createProspect, setCreateProspect] = useState(false);
    const [createProduct, setCreateProduct] = useState(false);
    const [showCallOut, setShowCallOut] = useState(() => {
        const hasVisitedScenarios = localStorage.getItem('hasVisitedScenarios');
        return !hasVisitedScenarios;
    });

    const [opened, { open, close }] = useDisclosure(false);

    const fetchScenarios = async (orgId) => {
        try {
            let fetchedScenarios = await ScenarioService.getAllScenariosForOrg(orgId);
            
            // If the user is not an admin, filter out inactive scenarios
            if (user.role !== 2) {
                fetchedScenarios = fetchedScenarios.filter(scenario => scenario.isActive);
            }
    
            setScenarios(fetchedScenarios);
        } catch (error) {
            console.log(error);
        }
    }

    const handleToggle = async (index) => {
        const scenarioToUpdate = scenarios[index];
        const updatedScenario = { ...scenarioToUpdate, isActive: !scenarioToUpdate.isActive };
    
        // Optimistically update the UI
        const updatedScenarios = [...scenarios];
        updatedScenarios[index] = updatedScenario;
        setScenarios(updatedScenarios);
    
        try {
            // Call the API to update the scenario
            await ScenarioService.updateScenario(updatedScenario);
        } catch (error) {
            console.error('Failed to update the scenario', error);
            // If the API call fails, revert the client-side state to its previous value
            updatedScenarios[index] = scenarioToUpdate;
            setScenarios(updatedScenarios);
    
            // TODO: Inform the user about the error, we can maybe use an existing library for this
            // For example: toast.error('Failed to update the scenario. Please try again.');
        }
    }

    const onScenarioCreate = async () => {
        setCreateScenario(false);
        await fetchScenarios(orgId);
    }

    const closeModal = () => {
        close();
        setSelectedScenario(null);
        setCreateScenario(false);
        setCreateProspect(false);
        setCreateProduct(false);
    }

    useEffect(() => {
        if (!localStorage.getItem('hasVisitedScenarios')) {
            localStorage.setItem('hasVisitedScenarios', 'true');
        }
    }, []);

    useEffect(() => {
        fetchScenarios(orgId);
    }, [orgId])

    useEffect(() => {
        if ((selectedScenario != null
                || selectedCallRecord != null
                || createScenario != false
                || createProduct != false
                || createProspect != false) == true) {
            open();
        } else {
            close();
        }
            
    }, [selectedScenario, selectedCallRecord, createScenario, createProduct, createProspect])

    return (
        <div className='scenarios'>
            <div className='scenarios-header'>
            <Group><h1>Scenarios</h1>
                <Popover width={400} position="bottom" withArrow shadow="md" backgroundColor='#056CF2' defaultOpened={showCallOut}>
                    <Popover.Target>
                        <img src="/icons/Info-filled.svg" style={{cursor:'pointer'}}/>
                    </Popover.Target>
                    <Popover.Dropdown style={{backgroundColor: '#056CF2', color:'#FFFFFF', borderColor:'#056CF2'}} p='1.4rem'>
                        <Flex direction='row' gap='1.62rem'>
                            <img src="/logo-white.svg" alt="Cognisell Logo"/>
                            <Stack gap={6}>
                                <Text size="md" fw={700}>What are Scenarios?</Text>
                                <Text size="sm">Scenarios are the templates that you can use to make your calls happen. Scenarios are made up of different building blocks: a buyer persona, a product/service being sold, and contextual details about the sales call.</Text>
                            </Stack>
                        </Flex>
                    </Popover.Dropdown>
                </Popover>
            </Group>
            <div className='new-buttons'>
                {user.role === 2 && <ButtonSecondary onClick={() => setCreateProspect(true)}>New Buyer</ButtonSecondary>}
                {user.role === 2 && <ButtonSecondary onClick={() => setCreateProduct(true)}>New Product</ButtonSecondary>}
                {user.role === 2 && <ButtonPrimary onClick={() => setCreateScenario(true)}>New Scenario</ButtonPrimary>} 
            </div>               
            </div>
        
            <div className='scenarios-body'>
                <div className="scenario-table-header">
                    <span className="scenario-name-header">Name</span>
                    {user.role === 2 && <span className="scenario-active-header">Active</span> }
                </div>
                <div className='scenario-table-rows'>
                    {scenarios ? scenarios.map((scenario, index) => (
                        <ScenarioRecord
                            name={scenario.name}
                            description={scenario.prompt}
                            numberOfCalls={5}
                            isAdmin={user.role == 2}
                            isActive={scenario.isActive}
                            onToggleActive={() => handleToggle(index)}
                            onClick={() => setSelectedScenario(scenario)}
                        />
                    )) : null}
                </div>
            </div>
            <div className='scenarios-modals'>
                <Modal opened={opened} onClose={closeModal} withCloseButton={false} centered size='xl' padding={0}>
                    {selectedScenario && 
                        <ScenarioModal 
                            user={user} 
                            scenario={selectedScenario} 
                            onClose={() => setSelectedScenario(null)} 
                            onOpenCallRecord={setSelectedCallRecord} 
                            onCloseScenario={setSelectedScenario}/>}
                    {createScenario && 
                        <ScenarioCreation 
                            orgId={orgId} 
                            onClose={() => setCreateScenario(false)} 
                            onSubmit={onScenarioCreate} />}
                    {createProspect && 
                        <ProspectCreation  
                            orgId={orgId} 
                            onClose={() => setCreateProspect(false)} />}
                    {createProduct && 
                        <ProductCreation 
                            orgId={orgId} 
                            onClose={() => setCreateProduct(false)} />}
                    {selectedCallRecord && 
                        <CallRecordModal 
                            user={user} 
                            callRecord={selectedCallRecord} 
                            onClose={() => setSelectedCallRecord(null)} />}
                </Modal>
            </div>       
      </div>  
    )
}

export default Scenarios;
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { stopSpeech, textToSpeechFullWait } from '../../utils/textToSpeech';
import { api } from '../../api/index';
import Avatar from '../modules/primitives/Avatar';

import { Button, SimpleGrid } from '@mantine/core';
import { IconChevronLeft } from '@tabler/icons-react';
import { Alert } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';

import './CallSimulation.css';

const subKey = process.env.REACT_APP_AZURE_SPEECH_RECOGNITION_KEY;
const region = process.env.REACT_APP_AZURE_REGION;

// TODO: Put this in an organized util file later.
function TypingAnimation() {
    const [dots, setDots] = useState('.');
  
    useEffect(() => {
      const interval = setInterval(() => {
        setDots((dots) => (dots.length < 3 ? dots + '.' : '.'));
      }, 500);
  
      return () => clearInterval(interval);
    }, []);
  
    return <p className='agent-subtitles'>{dots}</p>;
  }

const CallSimulation = ({ agent, callName, callPrompt, onCallRestart, onCallEnd }) => {
    const { transcript, resetTranscript, listening } = useSpeechRecognition();
    const [responseContent, setResponseContent] = useState('');
    const [elapsedTime, setElapsedTime] = useState(0);
    const [lastUpdateTime, setLastUpdateTime] = useState(null);
    const [callActive, setCallActive] = useState(false);
    const [callComplete, setCallComplete] = useState(false);
    const [waitingOnResponse, setWaitingOnResponse] = useState(false);
    const [warning, setWarning] = useState(false);
    const playingWelcomeMessageRef = useRef(false);
    const welcomeMessage = `Hello. I am the AI agent that will be roleplaying as the buyer, ${agent.prospect.name}. In this scenario, be sure to ask engaging questions, listen well, and seek the opportunities where you can solve my problems. \n\nWhen you are ready to begin, press the 'Start Call' button.`;

    const formatElapsedTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
    const startCall = () => {
        stopSpeech();
        setCallActive(true);
        setLastUpdateTime(Date.now());
        SpeechRecognition.startListening({continuous: true});
    }

    const restartCall = useCallback(async () => {
        setCallActive(false);

        stopSpeech();
        if (listening)
        {
            SpeechRecognition.stopListening();
        }
        setResponseContent("")

        await onCallRestart();
    }, []);

    const endCall = async () => {
        setCallActive(false);
        SpeechRecognition.stopListening();
        setCallComplete(true);
        await onCallEnd(elapsedTime);
    }

    const stopCall = useCallback(async () => {
        SpeechRecognition.stopListening();

        if (!transcript) {
            resetTranscript();
            SpeechRecognition.startListening({continuous: true});
            return;
        }

        try {
            setWaitingOnResponse(true);
            const response = await api.post('/agents/chat', {
                agentId: agent.id,
                message: transcript,
            });

            const agentResponse = response.data.message;
            setResponseContent(agentResponse);

            setWaitingOnResponse(false);
            resetTranscript();

            await playAgentResponse(agentResponse, agent.voice, setLastUpdateTime);

            if (response.data.hangUp == true) {
                endCall();
            }
        } catch (error) {
            console.error('Error sending message to agent: ', error);
        }
    }, [agent.id, transcript, resetTranscript]);


    // Plays welcome message when the component loads. Ensures
    // that only one message is playing at a time.
    useEffect(() => {
        if (playingWelcomeMessageRef.current) {
            return;
        }

        playingWelcomeMessageRef.current = true;
        setResponseContent(welcomeMessage);
        playIntro(welcomeMessage, agent.voice).finally(() => {
            playingWelcomeMessageRef.current = false;
        })
    }, [agent.prospect.name]);


    // Updates the timer showing how much time has passed during
    // an active call
    useEffect(() => {
        if (callActive) {
            const timer = setInterval(() => {
                setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
            }, 1000);
            return () => clearInterval(timer);
        } else {
            setElapsedTime(0);
        }
    }, [callActive])


    // Used to keep track of last time audio was ingested for the 
    // call
    useEffect(() => {
        if (listening && transcript) {
            setLastUpdateTime(Date.now());
        }
    }, [listening, transcript]);


    // Triggers a warning if the user is taking too long to respond
    useEffect(() => {
    if (listening && !warning) {
        const checkTime = setInterval(() => {
            if (Date.now() - lastUpdateTime > 5000) {
                setWarning(true);
            }
        }, 2000);
        return () => clearInterval(checkTime);
    }
    }, [listening, warning, lastUpdateTime]);


    // Used to submit current user message to the service and pause
    // the listening of the app temporarily 
    useEffect(() => {
        if (listening) {
            const timer = setInterval(() => {
                if (transcript && Date.now() - lastUpdateTime > 2000) {
                    stopCall();
                }
            }, 500);
            return () => clearInterval(timer);
        }
    }, [listening, transcript, lastUpdateTime, stopCall])


    // Ensures the microphone is turned off when the component
    // unmounts.
    useEffect(() => {
        return () => {
            stopSpeech();
            if (listening) {
                SpeechRecognition.stopListening();
            }
        };
    }, [listening])


    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
        return (
            <div>
                <p>Browser does not support speech recognition. Please use a supported browser.</p>
            </div>
        )
    }

    return (
        <div className="call-simulation">
            <div className="call-simulation-logo">
                <Link style={{textDecoration: 'none', color: '#222', display:'flex', flexDirection:'row', gap:'0.25rem', alignItems:'center' }} 
                        to={agent.product.orgId ? `/dash?orgId=${agent.product.orgId}` : '/dash'}>
                        <IconChevronLeft/>
                        <h3>Cognisell</h3>
                </Link>
            </div>

            <SimpleGrid cols={2} spacing={0} className='call-simulation-body' w='100%'>
                <div className='left'>
                    <div className='call-simulation-info'>
                        <div className='call-simulation-text'>
                            {/* TODO We should make the below code and ScenarioInfo class one and the same*/}
                            <div className="scenario-info">
                                <div className='scenario-info-container'>
                                    <div className='scenario-info-header'>
                                        <p>Scenario</p>
                                        <h1>{callName}</h1>
                                    </div>
                                    <div className='scenario-info-prompt'>
                                        {callPrompt}
                                    </div>
                                    <div className='scenario-info-block'>
                                        <div className='scenario-info-header'>
                                            <h2>Product</h2>
                                        </div>
                                        <Avatar src='/nonexistent' defaultImage='/icons/Cognisell-Black.svg' size='medium'/>
                                        <p><span className="info-label">Name:</span> {agent.product.name}</p>
                                        <p><span className="info-label">Description:</span> {agent.product.description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='call-button-controls'>
                            <Button 
                                onClick={callActive ? endCall : startCall}
                                color='black'
                                w='100%'>
                                {callActive ? 'End Call' : 'Start Call'}
                            </Button>
                            { callActive ? <Button onClick={restartCall} w='100%' variant='outline' color='black' style={{borderColor: '#EEE'}}>Restart</Button> : null }
                        </div>
                    </div>
                </div>
                <div className='right'>
                    <div className="agent-profile">
                        <div className="agent-image-wrapper">
                            <div className={`agent-expanding-circle${callActive ? ' active' : ''}`}/>
                            <Avatar src={agent.prospect.imageUri} size='xlarge'/>
                        </div>
                        <div className='agent-profile-header'>
                            {agent.prospect.name || 'AI Agent'}
                        </div>
                        <div className='agent-profile-subheader'>
                            <p>{agent.prospect.title || 'Prospect'}</p>
                            <p>{agent.prospect.company || 'Company'}</p>
                        </div>

                        { callActive ? <div className="timer">{formatElapsedTime(elapsedTime)}</div> : null }
                        { waitingOnResponse ? 
                            <TypingAnimation /> : 
                            <p className='agent-subtitles'>{responseContent}</p>
                        }
                    </div>
                    <div className='right-warning'>
                        {warning &&
                        <Alert styles={{root: {outline: '1px solid'}}} variant="light" color="yellow" radius="md" withCloseButton title="Warning" icon={<IconInfoCircle/>} onClick={() => setWarning(false)}>
                            You are taking too long to respond.
                        </Alert>}
                    </div>
                </div>
            </SimpleGrid>
        </div>
    );
};

const playIntro = async (welcomeMessage, voice) => {
    try {
        await textToSpeechFullWait(welcomeMessage, subKey, region, voice);
    } catch (error) {
        console.error('Error with text-to-speech: ', error);
    }

    return new Promise((resolve) => {
        setTimeout(resolve, 2000);
    });
};

const playAgentResponse = async (agentResponse, voice, setLastUpdateTime) => {
    try {
        await textToSpeechFullWait(agentResponse, subKey, region, voice);
    } catch (error) {
        console.error('Error with text-to-speech: ', error);
    } finally {
        SpeechRecognition.startListening({continuous: true});
        setLastUpdateTime(Date.now());  // Reset the timer after agent's response
    }
};

export default CallSimulation;

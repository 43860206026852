import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useAuth } from '../../../context/authContext';

import './Home.css';
import ButtonPrimary from '../primitives/ButtonPrimary';
import Overlay from '../../beta/Overlay';

const Home = () => {

    const [scrolled, setScrolled] = useState(false);
    const [playVideo, setPlayVideo] = useState(false);
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        } 

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    })

    useEffect(() => {
        if (isLoggedIn) {
            navigate('/dash');
        }
    }, [isLoggedIn, navigate])

    

    return (
        <div className='home'>
            {/* Home nav */}
            <div className={`homenav ${scrolled ? 'shadow' : ''}`}>
                <div className='homenav-logo-wrapper'>
                    {/* <div>{scrolled.strin}</div> */}
                    <div className='homenav-logo'>
                        <img src='/home/cognisell-logo-black.svg' title='Cognisell logo' alt='Cognisell logo'/>
                        <h2 onClick={() => navigate('/')}>Cognisell</h2>
                    </div>
                    </div>
                <div className='homenav-buttons'>
                    <a onClick={() => navigate('/login')}>Login</a>
                    <ButtonPrimary children={"Join the waitlist"} onClick={() => window.open('https://coda.io/form/Join-the-waitlist-for-Cognisell_dXJOFHTR5qV', '_blank')}/>
                </div>
            </div>

            {/* Home body */}
            <div className='home-body'>
                <div className='home-slogan max-width'>
                    <h1>
                        Learn how to sell better with real world sales calls.
                    </h1>
                    <h2>
                        Cognisell is intelligence-driven training for your sales teams, powered by AI
                    </h2>
                </div>
                <div className='home-hero banner max-width'>
                    {/*<img className='home-hero-banner-bg' src="/home/cognisell-home-clip.png" title='Cognisell banner Image' alt='Cognisell banner image'/>*/}
                    <video autoPlay loop muted playsInline>
                        <source src="/home/cognisell-hero-media.mp4" type="video/mp4"/>
                        Your browser does not support video.
                    </video>
                </div>
                <div className='home-customers max-width'>
                    <h3>Used by teams and educators at</h3>
                    <img className='home-hero-customer' src="/home/uw-logo.svg" title="University of Washington" alt="University of Washington Logo"/>
                </div>
                <div className='home-divider max-width'/>
                <div className='home-subslogan max-width'>
                    <h3>Sales enablement is hard, so we built Cognisell for your growing team.</h3>
                    <h4>
                        Teams face the pressing challenge of scaling, often hindered by outdated training methods and a lack of real-world practice opportunities.
                        Cognisell delivers engaging training experiences, orchestrated and measured by advanced AI frameworks, empowering you and your sales teams to excel.
                    </h4>
                </div>
                <Features />
                <div style={{width: '100%'}}>
                    <div className='home-built-for home-built-for-professionals'>
                        <h3>Built for sales leaders</h3>
                        <div className='home-built-for-benefits max-width'>
                            <div className='home-built-for-benefits-item'>
                                <p>Develop a formidable sales force that's ready to conquer market challenges</p>
                            </div>
                            <div className='home-built-for-benefits-item'>
                                <p>Experience training that mirrors real-world scenarios, honing your team's skills to perfection</p>
                            </div>
                            <div className='home-built-for-benefits-item'>
                                <p>Utilize analytical evaluations to drive performance improvements and strategy refinements</p>
                            </div>
                        </div>
                    </div>
                    <div className='home-built-for home-built-for-educators'>
                        <h3>Loved by Educators</h3>
                        <div className='home-built-for-benefits max-width'>
                            <div className='home-built-for-benefits-item'>
                                <p>Equip your students with skills that make them stand out in the competitive sales landscape</p>
                            </div>
                            <div className='home-built-for-benefits-item'>
                                <p>Engage students with interactive learning modules focused on all aspects of the sales process</p>
                            </div>
                            <div className='home-built-for-benefits-item'>
                                <p>Seamlessly incorporate Cognisell's platform into your existing curriculum</p>
                            </div>
                        </div>
                    </div>
                </div>
                <HomeCta />       
                <HomeFooter />
            </div>
        </div>
    )
};

export default Home;

// ***
// Components for home - seperated for easy editing
// ***

const Features = () => {
    return (
        <div className='features max-width'>
            <div className='features-card'>
                <div className='features-card-upper'>
                    <img className='features-card-icon'src="/home/new-features-icon-1.svg" />
                    <h3>Roleplay with AI Agents</h3>
                    <p>Sales calls are made to emulate the real world, making your sales force ready to tackle any situation. AI is trained on sales knowledge & data.</p>
                </div>
                <div className='features-card-lower'>
                    <img src="/home/new-features-1.svg"/>
                </div>
            </div>
            <div className='features-card'>
                <div className='features-card-upper'>
                    <img className='features-card-icon'src="/home/new-features-icon-2.svg" />
                    <h3>Get your performance evaluation</h3>
                    <p>We provide actionable and quantified feedback for each sales call, showing what you did well and what you can do better next time.</p>
                </div>
                <div className='features-card-lower'>
                    <img src="/home/new-features-2.svg"/>
                </div>
            </div>
            <div className='features-card'>
                <div className='features-card-upper'>
                    <img className='features-card-icon'src="/home/new-features-icon-3.svg" />
                    <h3>Customize prospect personas</h3>
                    <p>Create personas custom fit for your scenarios. We utilize the available information and  specific configurations you provide to ensure our agents speak and reason just like your customers do.</p>
                </div>
                <div className='features-card-lower'>
                    <img src="/home/new-features-3.svg"/>
                </div>
            </div>
            <div className='features-card'>
                <div className='features-card-upper'>
                    <img className='features-card-icon'src="/home/new-features-icon-4.svg" />
                    <h3>Manage & scale to your team</h3>
                    <p>What matters the most is the growth and experience your team gains in the long-term. With Cognisell's custom platform built to , you can track performance improvements in a number of different scenarios.</p>
                </div>
                <div className='features-card-lower'>
                    <img src="/home/new-features-4.svg"/>
                </div>
            </div>
        </div>
    );
}

const HomeCta = () => {
    const [role, setRole] = useState('enterprise');
    const navigate = useNavigate();

    return (
        <div className='home-cta'>
            <img src='/home/cognisell-logo-black.svg' title='Cognisell logo' alt='Cognisell logo'/>
            <div className='home-cta-header'>
                <h3>Ready to help your people be at their best game?</h3>
            </div>
            <div className='home-cta-button-area'>
                <ButtonPrimary children={'Join the waitlist today'} onClick={() => window.open('https://coda.io/form/Join-the-waitlist-for-Cognisell_dXJOFHTR5qV', '_blank')}/>
                
            </div>
        </div>
    )
}

const HomeFooter = () => {
    return (
        <div className='homefooter'>
            <div className='homefooter-section homefooter-section-logo'>
                <h3>Cognisell</h3>
            </div>
            <div className='homefooter-section'>
                <p className='homefooter-copyright'>&copy; {new Date().getFullYear()} Cognisell. All rights reserved.</p>
            </div>
        </div>
    )
}
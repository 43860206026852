import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useAuth } from '../context/authContext';
import LoadingSpinner from './LoadingSpinner';
import ButtonPrimary from './modules/primitives/ButtonPrimary';
import ButtonSecondary from './modules/primitives/ButtonSecondary';

import './Login.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false);
    const { login, requestPasswordReset } = useAuth();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);
        try {
            await login(email, password);
            navigate('/dash');
        } catch (error) {
            setError('Incorrect email or password.');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);
        try {
            await requestPasswordReset(email);
            setError('Password reset link sent to your email');
        } catch (error) {
            setError('Failed to send password reset link. Please contact the Cognisell team for support.');
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    const handleRegisterClick = () => {
        navigate('/register');
    };

    return (
        <div className="login">
            <div className="login-container">
                <div className='login-hero'>
                    <img src="/icons/Cognisell-White.svg" />
                </div>
                {loading ? (
                    <LoadingSpinner />
                ) : forgotPassword ? (
                    <form onSubmit={handleForgotPassword} className='login-form'>
                        <div className='login-form-header'>
                            <h2 className='login-form-header-text'>Reset Password</h2>
                            <p style={{"text-align": "center"}}>We'll send you a confirmation email with a link for you to reset your password.</p>
                            {error && <p style={{color:'red'}}>{error}</p>}
                        </div>    
                        <input
                            type="text"
                            placeholder="Enter email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                        <div className='login-form-buttons'>
                            <ButtonPrimary children="Submit" type="submit"/>
                        </div>
                        <button className="forgot-password-button" onClick={() => setForgotPassword(false)}>Back to Login</button>
                    </form>
                ) : (
                    <form onSubmit={handleSubmit} className='login-form'>
                        <div className='login-form-header'>
                            <h2 className='login-form-header-text'>Welcome</h2>
                            <p>Login below to get started</p>
                            {error && <p style={{color:'red'}}>{error}</p>}
                        </div>          
                        <div className='login-form-fields'>
                            <input
                                type="text"
                                placeholder="Enter email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                            <input
                                type="password"
                                placeholder="Enter password"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                        </div>
                        <div className='login-form-buttons'>
                            <ButtonPrimary children="Login" type="submit"/>
                            <ButtonSecondary children="Register" type="button" onClick={handleRegisterClick}/>
                        </div>
                        
                        <div className='no-account'>
                        <button className="forgot-password-button" type="button" onClick={() => setForgotPassword(true)}>Forgot Password?</button>
                        </div>                  
                    </form>
                )}
            </div>
        </div>
    );
};

export default Login;

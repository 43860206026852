import { authApi } from '../api';
import Product from '../models/Product';

class ProductService {
  static async getAllProductsForOrg(orgId) {
    try {
      const response = await authApi.get(`/orgs/products/${orgId}`);
      return response.data.map(product => new Product(product.id, product.name, product.description, product.orgId));
    } catch (error) {
      console.error('Error fetching products for org', error);
      throw error;
    }
  }

  static async createProduct(newProduct, pictureFile = null) {
    try {
      const response = await authApi.post('/api/orgs/products', newProduct);
      const newProduct = new Product(response.data.id, response.data.name, response.data.description, response.data.orgId);
    } catch (error) {
      console.error('Error creating new product');
    }

    if (pictureFile) {
      try {
        const formData = new FormData();
        formData.append("file", pictureFile);
        await authApi.post(`/api/orgs/${newProduct.orgId}/products/${newProduct.id}/image`, formData);
      } catch (error) {
        console.error(`Error uploading product image: ${error}`);
        return newProduct;
      }
    }

    return newProduct;
  }
}

export default ProductService;
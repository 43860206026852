import React from 'react';
import Avatar from '../primitives/Avatar';

import UserService from '../../../services/userService';

import './UserRecord.css';

const UserRecord = ({ firstName, lastName, email, globalId, role, onClick }) => {
    const roleClass = role === 'Admin' ? 'admin-role' : 'user-role';

    return (
        <div className="user-record" onClick={onClick}>
            <div className="user-record-left-content">
                <Avatar src={UserService.getUserProfilePicture(globalId)} size="medium"/>
                <div className='user-info'>
                    <h4 className='main'>{firstName} {lastName}</h4>
                    <p className='sub'>{email}</p>
                </div>
            </div>
            <div className={`user-record-right-content ${roleClass}`}>
                {/* We'll add 'Recordings Complete' and 'Last Active On' later */}
                <p>{role}</p>
            </div>
        </div>
    )
}

export default UserRecord;
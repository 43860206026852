class CallRecord {
    constructor(scenarioId, agentId, userEmail, userFirstName, userLastName, isComplete, callLengthInSeconds, evaluationModel, createdOn) {
      this.scenarioId = scenarioId;
      this.agentId = agentId;
      this.userEmail = userEmail;
      this.userFirstName = userFirstName;
      this.userLastName = userLastName;
      this.isComplete = isComplete;
      this.callLengthInSeconds = callLengthInSeconds
      this.evaluationModel = evaluationModel;
      this.createdOn = createdOn;
    }
  }

export default CallRecord;
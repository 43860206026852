import React from 'react';

import './ScenarioInfo.css'

const ScenarioInfo = ({scenario, prospect, product}) => {
    return (
        <div className="scenario-info">
            <div className='scenario-info-container'>
                <div className='scenario-info-header'>
                    <p>Scenario</p>
                    <h1>{scenario.name}</h1>
                </div>
                <div className='scenario-info-block'>
                    <div className='scenario-info-header'>
                        <h2>Buyer</h2>
                    </div>
                    <p><span className="info-label">Name:</span> {prospect.name}</p>
                    <p><span className="info-label">Company:</span> {prospect.company}</p>
                    <p><span className="info-label">Title:</span> {prospect.title}</p>
                </div>
                <div className='scenario-info-block'>
                    <div className='scenario-info-header'>
                        <h2>Product</h2>
                    </div>
                    <p><span className="info-label">Name:</span> {product.name}</p>
                    <p><span className="info-label">Description:</span> {product.description}</p>
                </div>
            </div>
        </div>
    )
}

export default ScenarioInfo;
class OrgUser {
    constructor(email, firstName, lastName, globalId, role) {
        this.email = email;
        this.firstName = firstName;
        this.lastName = lastName;
        this.globalId = globalId;
        this.role = role;
    }
}

export default OrgUser;
import { authApi } from '../api';
import Prospect from '../models/Prospect';
import ProspectImageModel from '../models/ProspectImageModel';

class ProspectService {
  static async getAllProspectsForOrg(orgId) {
    try {
      const response = await authApi.get(`/orgs/${orgId}/prospects`);
      return response.data.map(prospect => new Prospect(prospect.id, prospect.orgId, prospect.name, prospect.company,
        prospect.title, prospect.voice, prospect.imageUri, prospect.personalDetails, prospect.businessDetails));
    } catch (error) {
      console.error('Error fetching prospects for org', error);
      throw error;
    }
  }

  static async createProspect(newProspect) {
    try {
      const response = await authApi.post('/api/orgs/prospects', newProspect);
      return new Prospect(response.data.id, response.data.orgId, response.data.name, response.data.company,
        response.data.title, response.data.voice, response.data.imageUri, response.data.personalDetails, response.data.businessDetails);
    } catch (error) {
      console.error('Error creating new prospect', error);
    }
  }

  static async getProspectImageOptions() {
    try {
      const response = await authApi.get('/api/orgs/prospects/images');
      return response.data.map(image => new ProspectImageModel(image.name, image.uri));
    } catch (error) {
      console.error('Error fetching prospect images', error);
      return error;
    }
  }
}

export default ProspectService;
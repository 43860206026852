import React from 'react';
import './SidebarButton.css'

const SidebarButton = ({ label, icon, isActive, onActiveChange }) => {
  return (
    <div className={`sidebar-button ${isActive ? 'active' : ''}`} onClick={onActiveChange}>
      <div className="sidebar-icon-container">
        <img src={icon}/>
      </div>
      <span>{label}</span>
    </div>
  );
};

export default SidebarButton;

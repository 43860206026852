import React, { useState } from 'react';
import { useAuth } from '../../../context/authContext';
import { Link } from 'react-router-dom';
import UserService from '../../../services/userService';

import Avatar from './Avatar';

import './UserDropdown.css'

const UserDropdown = ({ user, userOrg, allUserOrgs, onOrgChange }) => {
  const { isLoggedIn, logout } = useAuth();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOrgSelection = (orgId) => {
    if(onOrgChange) {
      onOrgChange(orgId);
    }
    toggleDropdown(); // close the dropdown after changing the org
  };

  return (
    <div className={isOpen ? "user-dropdown-container open" : "user-dropdown-container"} onClick={toggleDropdown}>
      <div className='user-info-container'>
        <Avatar src={UserService.getUserProfilePicture(user.globalId)} size='medium' requiresAuth={true}/>
        <div className="user-info">
          <p className='main'>{user.firstName} {user.lastName}</p>
          <p className='sub'>{userOrg?.orgName || "Organization"}</p>
        </div>
      </div>
      
      {isOpen && (
        <div className="user-dropdown-menu">
          <div className="menu-header">
            <p>Account Options</p>
          </div>
          <div className='menu-header'>
            <p>Organizations</p>
            {allUserOrgs && allUserOrgs.map(org => (
              <div 
                key={org.orgId} 
                className={`org-item ${userOrg?.orgId === org.orgId ? 'active' : ''}`} 
                onClick={() => handleOrgSelection(org.orgId)}
              >
                {org.orgName}
              </div>
            ))}
          </div>
          <div style={{width:'100%', height: '1px', backgroundColor: '#EEE'}}/>
          <div className="menu-item" onClick={logout} ><Link to="/login">Logout</Link></div>
          {/* Add more options as needed */}
        </div>
      )}
    </div>
  );
};

export default UserDropdown;

import React, { useState, useEffect } from 'react';
import ScenarioService from '../../services/scenarioService';
import ProspectService from '../../services/prospectService';
import ProductService from '../../services/productService';
import './ScenarioModal.css';
import LoadingSpinner from '../LoadingSpinner';
import { Link, useNavigate } from 'react-router-dom';
import ScenarioCallRecord from '../modules/containers/ScenarioCallRecord';
import { ActionIcon, Button } from '@mantine/core';
import { IconArrowLeft, IconPlayerPlay, IconPlayerPlayFilled } from '@tabler/icons-react';
import Avatar from '../modules/primitives/Avatar';

const ScenarioModal = ({ user, scenarioId, scenario: givenScenario, onClose, onOpenCallRecord, onCloseScenario }) => {
  const [scenario, setScenario] = useState(givenScenario);
  const [prospect, setProspect] = useState(null);
  const [product, setProduct] = useState(null);
  const [error, setError] = useState(null);
  const [callRecords, setCallRecords] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchScenario = async () => {
      try {
        let fetchedScenario = givenScenario;
        if (!fetchedScenario) {
            fetchedScenario = await ScenarioService.getScenario(scenarioId);
            setScenario(fetchedScenario);
        }

        const prospect = await ProspectService.getAllProspectsForOrg(scenario.organizationId);
        const product = await ProductService.getAllProductsForOrg(scenario.organizationId);

        setProspect(prospect.find(p => p.id === scenario.prospectId));
        setProduct(product.find(p => p.id === scenario.productId));

        if (user && user.role === 2) {
          // By default, sort the records by most recent date
          let records = await ScenarioService.getCallRecordsForScenario(scenario.id);
          records = records.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
          setCallRecords(records);
        }
      } catch (err) {
        setError(err);
      }
    };

    fetchScenario();
  }, [scenarioId, user]);

  if (!scenario || !prospect || !product) {
    return error ? <div>Error: {error.message}</div> : <div className='scenario-modal'><LoadingSpinner /></div>;
  }

  return (
    <div className="scenario-modal">
      <div className='header-wrapper'>

          <ActionIcon
            variant='transparent' color='black' onClick={onClose}>
              <IconArrowLeft/>
          </ActionIcon>
          <div className="scenario-header-top">
              <h1>{scenario.name}</h1>
              <Link to={`/scenarios/${scenario.id}/chat`}>
                  <Button color='#056CF2' leftSection={<IconPlayerPlayFilled size={14}/>}>Play</Button>
              </Link>
          </div>
          <p>{scenario.prompt}</p>
      </div>
      <div className='scenario-body-container'> 
        <div className='scenario-body'>
          <div className='scenario-info'>
              <div className='prospect-info'>
                  <div className='scenario-info-header'>
                    <h2>Prospect Details</h2>
                  </div>
                  <Avatar src={prospect.imageUri} size='medium' />
                  <p><span className="info-label">Name:</span> {prospect.name}</p>
                  <p><span className="info-label">Company:</span> {prospect.company}</p>
                  <p><span className="info-label">Title:</span> {prospect.title}</p>
              </div>
              <div className='product-info'>
                <div className='scenario-info-header'>
                  <h2>Product Details</h2>
                </div>
                  <Avatar src='/nonexistent' defaultImage='/icons/Cognisell-Black.svg' size='medium'/>
                  <p><span className="info-label">Name:</span> {product.name}</p>
                  <p><span className="info-label">Description:</span> {product.description}</p>
              </div>
          </div>
          {callRecords &&
          <div className='call-records'>
            <div className='call-records-body-wrapper'>
              <div className='call-records-body-table-header'>
                <div className='call-records-body-table-header-left'>
                  <span className='call-records-name-header'>Call records</span>
                </div>
                <div className='call-records-body-table-header-right'>
                  <span className='call-records-time-header'>Duration</span>
                  <span className='call-records-date-header'>Performed on</span>
                </div>
              </div>
              <div className='call-records-body'>
                {callRecords ? callRecords.map((record, index) => (
                  <ScenarioCallRecord
                    key={index}
                    firstName={record.userFirstName}
                    lastName={record.userLastName}
                    email={record.userEmail}
                    isComplete={record.isComplete}
                    callLength={record.callLengthInSeconds}
                    performedOn={new Date(record.createdOn).toLocaleDateString()}
                    onClick={x => navigate(`/scenarios/${scenario.id}/replay/${record.agentId}`)}
                  />
                )) : null }
              </div>
            </div>
          </div>
          }
        </div>
      </div>
    </div>
  );
};

export default ScenarioModal;

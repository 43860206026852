class Scenario {
    constructor(id, orgId, prospectId, productId, name, prompt, type, isActive) {
        this.id = id;
        this.organizationId = orgId;
        this.prospectId = prospectId;
        this.productId = productId;
        this.name = name;
        this.prompt = prompt;
        this.type = type;
        this.isActive = isActive;
    }
}

export default Scenario;
import React, { useEffect, useState } from 'react';

import UserService from '../../../services/userService';

import UserDropdown from '../primitives/UserDropdown';
import SidebarButton from '../primitives/SidebarButton';
import DropdownButton from '../primitives/DropdownButton';

import './Sidebar.css';

const Sidebar = ({ user, userOrg, allUserOrgs, onActiveOptionChange, onOrgChange }) => {
  const [orgGroups, setOrgGroups] = useState([]);
  const [activeOption, setActiveOption] = useState("Scenarios"); // For now, starting w/ Scenarios as default active

  useEffect(() => {
    if(userOrg && userOrg.orgId) {
      UserService.getAllGroupsForOrg(userOrg.orgId)
        .then(groups => {
          setOrgGroups(groups.map(group => ({ name: group.name, id: group.id })));
        })
        .catch(error => {
          console.error('Error fetching groups:', error);
        });
    }
  }, [userOrg.orgId]);

  const handleActiveOptionChange = (option) => {
    setActiveOption(option);
    onActiveOptionChange(option);
  };

  const handleOrgChange = (orgId) => {
    onOrgChange(orgId);
  };

  return (
    /* <div className="sidebar"> */
    <div className="sidebar-latest">
      <div className="sidebar-header">
        <h3>Cognisell</h3>
      </div>
      <div className='sidebar-user-wrapper'>
        <UserDropdown user={user} userOrg={userOrg} allUserOrgs={allUserOrgs} onOrgChange={handleOrgChange} />
      </div>
      <div className='sidebar-divider'/>
      <div className='sidebar-buttons'>
        <SidebarButton 
          label="Scenarios" 
          icon='/icons/scenarios.svg'
          isActive={activeOption === "Scenarios"}
          onActiveChange={() => handleActiveOptionChange("Scenarios")}
        />

        { /* Admin Options */}
        { user.role == 2 && <SidebarButton 
          label="Buyers" 
          icon='/icons/Buyers.svg' 
          isActive={activeOption === "Buyers"}
          onActiveChange={() => handleActiveOptionChange("Buyers")}
        /> }
        { user.role == 2 && <SidebarButton 
          label="Products" 
          icon='/icons/Products.svg' 
          isActive={activeOption === "Products"}
          onActiveChange={() => handleActiveOptionChange("Products")}
        /> }
        {/* user.role == 2 && <DropdownButton 
          label="Groups" 
          icon='/icons/groups.svg' 
          options={orgGroups}
          isActive={activeOption === "Groups"}
          onActiveChange={() => handleActiveOptionChange("Groups")}
        /> */}
        {user.role == 2 && <SidebarButton 
          label="Users" 
          icon='/icons/users.svg'
          isActive={activeOption === "Users"}
          onActiveChange={() => handleActiveOptionChange("Users")}
        />}
        {/* User Options */}
        {user.role !== 2 && <SidebarButton
          label="My Recordings"
          icon='/icons/CallHistory.svg'
          isActive={activeOption === "Recordings"}
          onActiveChange={() => handleActiveOptionChange("Recordings")}
        />}
      </div>
    </div>
  );
};

export default Sidebar;

import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import ScenarioService from "../../../services/scenarioService";
import ProspectService from '../../../services/prospectService';
import ProductService from '../../../services/productService';

import LoadingSpinner from '../../LoadingSpinner';
import ScenarioInfo from '../containers/ScenarioInfo';
import Evaluation from '../containers/Evaluation';
import { SimpleGrid } from '@mantine/core';
import { IconChevronLeft } from '@tabler/icons-react';

import './RecordingReplay.css';

const RecordingReplay = () => {
    const { scenarioId, callId } = useParams();
    const [scenario, setScenario] = useState(null);
    const [prospect, setProspect] = useState(null);
    const [product, setProduct] = useState(null);
    const [callRecord, setCallRecord] = useState(null);

    useEffect(() => {
        const fetchAllData = async () => {
            if (scenarioId) {
                const scenarioResponse = await ScenarioService.getScenario(scenarioId);
                setScenario(scenarioResponse);

                if (scenarioResponse) {
                    const prospectData = await ProspectService.getAllProspectsForOrg(scenarioResponse.organizationId);
                    const productData = await ProductService.getAllProductsForOrg(scenarioResponse.organizationId);

                    setProspect(prospectData.find(p => p.id === scenarioResponse.prospectId));
                    setProduct(productData.find(p => p.id === scenarioResponse.productId));

                    if (callId) {
                        const recordResponse = await ScenarioService.getCallRecord(scenarioId, callId);
                        setCallRecord(recordResponse);

                    }
                }
            }
        }

        fetchAllData();
    }, [scenarioId, callId])

    if (!scenario || !prospect || !product || !callRecord) {
        return (
            <div className='recording-replay'>
                <LoadingSpinner />
            </div>
        )
    }

    return (
        <div className="recording-replay">
            <div className="recording-replay-logo">
                <Link style={{textDecoration: 'none', color: '#222', display:'flex', flexDirection:'row', gap:'0.25rem', alignItems:'center' }} 
                    to={scenario.organizationId ? `/dash?orgId=${scenario.organizationId}` : '/dash'}>
                    <IconChevronLeft/>
                    <h3>Cognisell</h3>
                </Link>
            </div>
            <SimpleGrid cols={2} spacing={0} className='recording-replay-body'>
                <div className='left'>
                    <ScenarioInfo scenario={scenario} prospect={prospect} product={product}/>
                </div>
                <div className='right'>
                    <Evaluation callRecord={callRecord}/>
                </div>
            </SimpleGrid>
        </div>
    )
}

export default RecordingReplay;

class Agent {
    constructor(id, scenarioId, messages, prospect, product, voice, difficulty) {
      this.id = id;
      this.scenarioId = scenarioId;
      this.messages = messages;
      this.prospect = prospect;
      this.product = product;
      this.voice = voice;
      this.difficulty = difficulty;
    }
}

export default Agent;
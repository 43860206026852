import React from 'react';
import './ScenarioRecord.css';
import { Text } from '@mantine/core';

const ScenarioRecord = ({ name, description, numberOfCalls, isActive, isAdmin, onToggleActive, onClick }) => {
    return (
        <div className="scenario-record">
            <div className="left-content" onClick={onClick}>
                <div className="scenario-name">{name}</div>
                <Text lineClamp={1} size='0.75rem' c='#666'>{description}</Text>
            </div>
            {isAdmin && (
                <div className="right-content">
                    {/*<span className="calls">{numberOfCalls} Calls</span> */}
                    <label className="toggle-switch">
                        <input 
                            type="checkbox" 
                            checked={isActive}
                            onChange={e => {
                                onToggleActive();
                            }} 
                        />
                        <span className="slider"></span>
                    </label>
                </div>
            )}
        </div>
    );
};

export default ScenarioRecord;
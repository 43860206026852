import React from "react";

import './LoadingResults.css';
import Avatar from "../primitives/Avatar";

const LoadingResults = () => {
    return (
        <div className="loading-results">
            <Avatar size="xxlarge" src="/icons/LoadingResults.svg"/>
            <div className="loading-results-subheading">
                <div className="loading-spinner" />
                <p>Loading your results</p>
            </div>
        </div>
    )
}

export default LoadingResults;
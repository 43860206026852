import * as sdk from 'microsoft-cognitiveservices-speech-sdk';

let audioElementRef = null;
let rejectRef = null;

export async function textToSpeechFullWait(text, subscriptionKey, serviceRegion, voice) {
    return new Promise((resolve, reject) => {
        rejectRef = reject;
        const speechConfig = sdk.SpeechConfig.fromSubscription(subscriptionKey, serviceRegion);
        speechConfig.speechSynthesisVoiceName = voice ? voice : 'en-US-JasonNeural';

        const synthesizer = new sdk.SpeechSynthesizer(speechConfig, null);

        synthesizer.speakTextAsync(
            text,
            (result) => {
                if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
                    synthesizer.close();
                    
                    const blob = new Blob([result.audioData], { type: 'audio/wav' });
                    const audioUrl = URL.createObjectURL(blob);
                    //const audioElement = new Audio(audioUrl);
                    audioElementRef = new Audio(audioUrl);

                    //audioElement.addEventListener
                    audioElementRef.addEventListener('ended', () => {
                        URL.revokeObjectURL(audioUrl);
                        resolve();
                    });

                    //audioElement.play(); 
                    audioElementRef.play();              
                } else {
                    synthesizer.close();
                    reject(new Error('Speech synthesis failed'));
                }
            },
            (error) => {
                synthesizer.close();
                reject(error);
            }
        );
    });
}

export function stopSpeech() {
    if (audioElementRef) {
        audioElementRef.pause();
        if (rejectRef) {
            rejectRef(new Error('Audio playback stopped by user'));
        }
    }
}
class Prospect {
    constructor(id, orgId, name, company, title, voice, imageUri, personalDetails, businessDetails) {
        this.id = id;
        this.orgId = orgId;
        this.name = name;
        this.company = company;
        this.title = title;
        this.voice = voice;
        this.imageUri = imageUri;
        this.personalDetails = personalDetails;
        this.businessDetails = businessDetails;
    }
}

export default Prospect;
import React, { useState, useEffect } from 'react';
import { textToSpeechFullWait } from '../../../utils/textToSpeech';
import ProspectService from '../../../services/prospectService';

import WizardModal from './WizardModal';

import './ProspectCreation.css';
import ButtonPrimary from '../primitives/ButtonPrimary';
import ButtonSecondary from '../primitives/ButtonSecondary';
import { SimpleGrid } from '@mantine/core';
import Avatar from '../primitives/Avatar';

const subKey = process.env.REACT_APP_AZURE_SPEECH_RECOGNITION_KEY;
const region = process.env.REACT_APP_AZURE_REGION;

const ProspectCreation = ({ orgId, onClose }) => {
    const [step, setStep] = useState(0);
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [title, setTitle] = useState('');
    const [voice, setVoice] = useState('');
    const [avatarUri, setAvatarUri] = useState('');
    const [prospectImages, setPropsectImages] = useState([]);
    const [personalDetails, setPersonalDetails] = useState([]);
    const [businessDetails, setBusinessDetails] = useState([]);

    const voiceOptions = {
        "Voice 1": "en-US-JasonNeural",
        "Voice 2": "en-US-DavisNeural",
        "Voice 3": "en-US-SteffanNeural", 
        "Voice 4": "en-US-NancyNeural",
        "Voice 5": "en-US-SaraNeural",
        "Voice 6": "en-US-CoraNeural"
    };

    const fetchData = async () => {
        let fetchedBuyerImages = await ProspectService.getProspectImageOptions();
        setPropsectImages(fetchedBuyerImages);
    }

    const handleSubmit = async () => {
        let newProspect = {
            name, orgId, company, title, voice, avatarUri,
            personalDetails, businessDetails
        };
        try {
            await ProspectService.createProspect(newProspect);
            setName('');
            setCompany('');
            setTitle('');
            setVoice('');
            setAvatarUri('');
            setPersonalDetails([]);
            setBusinessDetails([]);
            onClose();
        } catch (error) {
            console.error('Error creating new prospect', error);
        }
    }

    const steps = [
        <SetBasicInfoStep onNameSet={setName} currentName={name} onCompanySet={setCompany} currentCompany={company} onTitleSet={setTitle} currentTitle={title} />,
        <SetVoiceStep voiceOptions={voiceOptions} onVoiceSet={setVoice} currentVoice={voice}/>,
        <SetAvatarStep avatars={prospectImages} onAvatarSet={setAvatarUri} currentAvatarUri={avatarUri}/>,
        <SetDetailsStep onBusinessDetailsSet={setBusinessDetails} currentBusinessDetails={businessDetails} />,
        <SetReviewStep name={name} company={company} title={title} voice={voice} avatarUri={avatarUri} businessDetails={businessDetails} voiceOptions={voiceOptions} />
    ]

    const descriptions = [
        "Set the basic information for this buyer persona.",
        "Choose a voice you feel best represents this persona.",
        "Provide any contextual information that will make this buyer persona more accurate and realistic to your needs.",
        "Review all the final information for your new persona."
    ]

    useEffect(() => {
        fetchData();
    }, [orgId]);

    return (
        <WizardModal
            title='Create a new Buyer Profile'
            descriptions={descriptions}
            steps={steps}
            onNext={() => setStep(step + 1)}
            onPrev={() => setStep(step - 1)}
            onCancel={() => onClose()}
            onSubmit={() => handleSubmit()}
        />
    )
}

export default ProspectCreation;

// Prospect Creation Steps

// 1. BasicInfo
const SetBasicInfoStep = ({ onNameSet, currentName, onCompanySet, currentCompany, onTitleSet, currentTitle }) => {
    const [name, setName] = useState(currentName);
    const [company, setCompany] = useState(currentCompany);
    const [title, setTitle] = useState(currentTitle);

    const handleNameChange = (e) => {
        setName(e.target.value);
        if (onNameSet) onNameSet(e.target.value);
    }

    const handleCompanyChange = (e) => {
        setCompany(e.target.value);
        if (onCompanySet) onCompanySet(e.target.value);
    }

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
        if (onTitleSet) onTitleSet(e.target.value);
    }

    return (
        <div className='info-step'>
            <div className='info-step-field'>
                <p>Name</p>
                <input
                    type='text'
                    value={name}
                    onChange={handleNameChange}
                    placeholder='Enter name'
                />
            </div>
            <div className='info-step-field'>
                <p>Company</p>
                <input
                    type='text'
                    value={company}
                    onChange={handleCompanyChange}
                    placeholder='Enter company'
                />
            </div>
            <div className='info-step-field'>
                <p>Title</p>
                <input
                    type='text'
                    value={title}
                    onChange={handleTitleChange}
                    placeholder='Enter job title'
                />
            </div>
        </div>
    )
}

// 2. Voice
const SetVoiceStep = ({ voiceOptions, onVoiceSet, currentVoice }) => {
    const [selectedVoice, setSelectedVoice] = useState(currentVoice);

    const handleSelectedVoice = (voice) => {
        setSelectedVoice(voice);
        onVoiceSet(voice);
    }

    const playVoiceSample = async (voiceKey) => {
        const samples = [
            'Hi, nice to meet you',
            'I appreciate your call today',
            'Can you tell me more how your product aligns with our goals?',
            'This sounds interesting, but we just dont have the budget'
        ]
        let i = Math.floor(Math.random() * samples.length);
        await textToSpeechFullWait(samples[i], subKey, region, voiceOptions[voiceKey]);
    }

    return (
        <div className='voice-step'>
            {voiceOptions ? Object.keys(voiceOptions).map((voiceKey, index) => (
                <div
                    key={index}
                    className={`voice-step-card ${selectedVoice === voiceOptions[voiceKey] ? 'selected' : ''}`}
                    onClick={() => handleSelectedVoice(voiceOptions[voiceKey])}
                >
                    <div className='voice-step-card-left'>
                        <p className='voice-step-card-name'>{voiceKey}</p>
                        {/* Add description if necessary */}
                        {/* <p className='voice-step-card-description'>{voiceDescriptionHere}</p> */}
                    </div>
                    <div className='voice-step-card-right'>
                        <ButtonSecondary children={"Play Sample"} onClick={() => playVoiceSample(voiceKey)} />
                    </div>
                </div>
            )) : null}
        </div>
    )
}

// 3. Choose Avatar
const SetAvatarStep = ({ avatars, onAvatarSet, currentAvatarUri = null }) => {
    const [selectedAvatarUri, setSelectedAvatarUri] = useState(currentAvatarUri);

    const handleSelectedAvatar = (avatar) => {
        setSelectedAvatarUri(avatar);
        onAvatarSet(avatar.uri);
    }

    return (
        <SimpleGrid cols={4} className='prospect-step'>
            {avatars ? avatars.map((avatar, index) => (
                <div
                    key={index}
                    className={`prospect-step-card ${selectedAvatarUri === avatar ? 'selected' : ''}`}
                    onClick={() => handleSelectedAvatar(avatar)}
                >
                    <Avatar size='large' src={avatar.uri}/>
                </div>
            )) : null}
        </SimpleGrid>
    )
}

// 4. PersonaDetails
const SetDetailsStep = ({ onBusinessDetailsSet, currentBusinessDetails }) => {
    const [businessDetails, setBusinessDetails] = useState(currentBusinessDetails || []);
    const [currentDetail, setCurrentDetail] = useState('');

    const addDetail = () => {
        if (currentDetail && businessDetails.length < 7) {
            setBusinessDetails([...businessDetails, currentDetail]);
            setCurrentDetail('');
            console.log(businessDetails);
        }
    }

    const removeDetail = (index) => {
        const newDetails = [...businessDetails];
        newDetails.splice(index, 1);
        setBusinessDetails(newDetails);
    }

    useEffect(() => {
        if (onBusinessDetailsSet) {
            onBusinessDetailsSet(businessDetails);
        }
    }, [businessDetails]);

    return (
        <div className='details-step'>
            <div className='detail-input-container'>
                <input
                    type='text'
                    value={currentDetail}
                    onChange={(e) => setCurrentDetail(e.target.value)}
                    placeholder='Enter a business detail'
                />
                <ButtonPrimary children={"Add"} onClick={() => addDetail()} />
            </div>
            {businessDetails.map((detail, index) => (
                <div key={index} className='detail-item'>
                    <p>{detail}</p>
                    <ButtonSecondary children={"Remove"} onClick={() => removeDetail(index)} />
                </div>
            ))}
        </div>
    )
}

// 5. Review
const SetReviewStep = ({ name, company, title, voice, avatarUri, businessDetails, voiceOptions }) => {
    const playVoiceSampleReview = async () => {
        const samples = [
            'Hello, this is a review of the voice you selected.',
            'This is how your chosen voice sounds.',
            'You can change the voice if you wish.'
        ]
        let i = Math.floor(Math.random() * samples.length);
        await textToSpeechFullWait(samples[i], subKey, region, voice);
    }

    // Function to get the voice name by its value
    const getVoiceName = (voiceValue) => {
        return Object.keys(voiceOptions).find(key => voiceOptions[key] === voiceValue);
    }

    return (
        <div className='review-step'>
            
            {/* Prospect Details Section */}
            <div className='review-step-section'>
                <p className='review-step-section-title'>Prospect Details</p>
                <div className='review-step-prospect-container'>
                    <div className='review-step-prospect-container-organizer'>
                        <div>
                            <p style={{fontWeight: 'bold'}}>{name}</p>
                            <p>{company} - {title}</p>
                        </div>
                        {/* Add an Avatar or image if needed */}
                        <Avatar src={avatarUri}/>
                    </div>
                </div>
            </div>

            {/* Voice Section */}
            <div className='review-step-section'>
                <p className='review-step-section-title'>Voice Chosen</p>
                <div className='voice-step-card'>
                    <p style={{fontWeight: 'bold'}}>{getVoiceName(voice)}</p>
                    <ButtonSecondary children={"Play Sample"} onClick={playVoiceSampleReview} />
                </div>
            </div>
            
            {/* Business Details Section */}
            <div className='review-step-section'>
                <p className='review-step-section-title'>Business Details</p>
                <div className='review-step-details-container'>
                    {businessDetails.map((detail, index) => (
                        <p key={index}>{detail}</p>
                    ))}
                </div>
            </div>
        </div>
    )
}
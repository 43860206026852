import React, { useState, useEffect } from 'react';

import { useDisclosure } from '@mantine/hooks';
import { Flex, Group, Modal, Popover, Stack, Text } from '@mantine/core';

import ProductService from '../../../services/productService';
import ButtonPrimary from '../primitives/ButtonPrimary';

import './Products.css';
import ProductCreation from '../containers/ProductCreation';
import ProductRecord from '../containers/ProductRecord';

const Products = ({user, orgId}) => {
    const [products, setProducts] = useState([]);
    const [createProduct, setCreateProduct] = useState(false);
    const [showCallOut, setShowCallOut] = useState(() => {
        return !localStorage.getItem('hasVisitedProducts');
    });

    const [opened, {open, close}] = useDisclosure(false);

    const fetchProducts = async (orgId) => {
        try {
            let fetchedProducts = await ProductService.getAllProductsForOrg(orgId);
            setProducts(fetchedProducts);
        } catch (error) {
            console.log(error);
        }
    }

    const closeModal = () => {
        close();
        setCreateProduct(false);
    }

    useEffect(() => {
        if (!localStorage.getItem('hasVisitedProducts')) {
            localStorage.setItem('hasVisitedProducts', 'true');
        }
    }, [])

    useEffect(() => {
        fetchProducts(orgId);
    }, [orgId])

    return (
        <div className='products'>
            <div className='products-header'>
                <Group>
                    <h1>Products</h1>
                    <Popover width={400} position="bottom" withArrow shadow="md" backgroundColor='#056CF2' defaultOpened={showCallOut}>
                        <Popover.Target>
                            <img src="/icons/Info-filled.svg" style={{cursor:'pointer'}}/>
                        </Popover.Target>
                        <Popover.Dropdown style={{backgroundColor: '#056CF2', color:'#FFFFFF', borderColor:'#056CF2'}} p='1.4rem'>
                            <Flex direction='row' gap='1.62rem'>
                                <img src="/logo-white.svg" alt="Cognisell Logo"/>
                                <Stack gap={6}>
                                    <Text size="md" fw={700}>What are Products?</Text>
                                    <Text size="sm">Products are the goods and services you will be representing and selling withing your Cognisell sales simulations.</Text>
                                </Stack>
                            </Flex>
                        </Popover.Dropdown>
                    </Popover>
                </Group>
                <div className='new-buttons'>
                    <ButtonPrimary onClick={() => {setCreateProduct(true); open();}}>New Product</ButtonPrimary>
                </div>
            </div>
            <div className='products-body'>
                <div className='products-table-header'>
                    <span className='products-name-header'>Name</span>
                </div>
                <div className='products-table-rows'>
                    {products ? products.map((product, index) => (
                        <ProductRecord
                            key={product.id}
                            name={product.name}
                            description={product.description}
                        />
                    )) : null}
                </div>
            </div>
            <div className='scenario-modals'>
                <Modal opened={opened} onClose={closeModal} withCloseButton={false} centered size='xl' padding={0}>
                    {createProduct &&
                        <ProductCreation orgId={orgId} onClose={() => {setCreateProduct(false); closeModal()}}/>}
                </Modal>
            </div>
        </div>
    )
}

export default Products;
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { AuthProvider } from './context/authContext';

import DevelopmentPage from './components/modules/dev/DevelopmentPage';

import './App.css' 
import './components/global.css'
import Login from './components/Login';
import Register from './components/Register';
import PasswordReset from './components/PasswordReset';
import Dashboard from './components/beta/Dashboard';
import Simulation from './components/beta/Simulation';
import RecordingReplay from './components/modules/views/RecordingReplay';
import Home from './components/modules/views/Home';

function App() {
  return (
    <Router>
      <AuthProvider>
        <div className="App">

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/password-reset" element={<PasswordReset />}/>
            <Route path="/dash" element={<Dashboard />}/>
            <Route path="/scenarios/:scenarioId/chat" element={<Simulation />} />
            <Route path="/scenarios/:scenarioId/replay/:callId" element={<RecordingReplay />}/>
            {process.env.NODE_ENV !== 'production' && (
              <Route path="/dev/:componentName" element={<DevelopmentPage />}/>
            )}
          </Routes>

        </div>
      </AuthProvider>     
    </Router>
  );
}

export default App;

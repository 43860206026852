import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import AgentService from '../../services/agentService';
import ScenarioService from '../../services/scenarioService';

import CallSimulation from '../scenarios/CallSimulation';
import LoadingResults from '../modules/containers/LoadingResults';
import Evaluation from '../modules/containers/Evaluation';
import LoadingSpinner from '../LoadingSpinner';
import { MIN_CALL_TIME_SECONDS } from '../../utils/constants';

import './Simulation.css'


const Simulation = () => {
  const { scenarioId } = useParams();
  const [agent, setAgent] = useState(null);
  const [scenario, setScenario] = useState(null);
  const [callRecord, setCallRecord] = useState(null);
  const [callEnded, setCallEnded] = useState(false);
  const [isLoadingResults, setIsLoadingResults] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    const fetchAgent = async () => {
      try {
        const scenarioResponse = await ScenarioService.getScenario(scenarioId);
        if (isMounted) setScenario(scenarioResponse);
        const newAgent = await AgentService.createAgentByScenarioId(scenarioId);
        if (isMounted) setAgent(newAgent);
      } catch (error) {
        console.error('Error fetching agent and scenario:', error);
      }
    };

    fetchAgent();

    return () => {
      isMounted = false;
    };
  }, [scenarioId]);

  const onCallRestart = () => {
    const fetchAgent = async () => {
      try {
        const newAgent = await AgentService.createAgentByScenarioId(scenarioId);
        setAgent(newAgent);
      } catch (error) {
        console.error('Error fetching new agent:', error);
      }
    };

    fetchAgent();
    setCallEnded(false);
  };

  const onCallEnd = async (elapsedTimeInSeconds) => {
    setIsLoadingResults(true);
    setCallEnded(true);

    // If the call does not last as long as the specified threshold, we do not count it
    // and do not send a call record to be submitted
    if (elapsedTimeInSeconds < MIN_CALL_TIME_SECONDS) {
      navigate(`/dash?orgId=${scenario.organizationId}`);
      return;
    }

    try {
      const record = await AgentService.submitCallRecord(scenarioId, agent.id, elapsedTimeInSeconds);
      setCallRecord(record);
      setCallEnded(true);
      setIsLoadingResults(false);
      navigate(`/scenarios/${scenarioId}/replay/${agent.id}`);
    } catch (error) {
      console.error('Error submitting call record:', error);
      setIsLoadingResults(false);
      // Potentially show an error message to the user or handle it differently
    }
  };

  if (!agent || !scenario) {
    return (
      <div className='simulation-page'>
        <div style={{
            display: 'flex',
            justifyItems: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
          }}>
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  return (
    <div className="simulation-page">     
      {!callEnded ? (
        <CallSimulation agent={agent} callName={scenario.name} callPrompt={scenario.prompt} onCallRestart={onCallRestart} onCallEnd={onCallEnd} />
      ) : (
        isLoadingResults ? (
          <LoadingResults />
        ) : (
          <div className='results-container'>
            <Evaluation callRecord={callRecord}/>
            <Link to="/dash">
              <button className='return-button'>Return Home</button>
            </Link>
          </div>
        )
        )}
    </div>
  );
};

export default Simulation;

import React, { useState, useEffect } from 'react';

import ProspectService from '../../../services/prospectService';
import ProductService from '../../../services/productService';
import ScenarioService from '../../../services/scenarioService';

import WizardModal from './WizardModal';
import Avatar from '../primitives/Avatar';

import './ScenarioCreation.css';
import { SimpleGrid } from '@mantine/core';

const ScenarioCreation = ({ orgId, onClose, onSubmit }) => {
    const [step, setStep] = useState(0);
    const [name, setName] = useState('');
    const [prompt, setPrompt] = useState('');
    const [evalType, setEvalType] = useState(0); // Defaulting to SPIN, for now
    const [isActive, setIsActive] = useState(true); // Defaulting to always active, for now
    const [prospect, setProspect] = useState(null);
    const [product, setProduct] = useState(null);
    const [prospects, setProspects] = useState([]);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        let fetchedProspects = await ProspectService.getAllProspectsForOrg(orgId);
        let fetchedProducts = await ProductService.getAllProductsForOrg(orgId);
        setProspects(fetchedProspects);
        setProducts(fetchedProducts);

        // Set default starting values
        if (fetchedProspects.length > 0) {
            setProspect(fetchedProspects[0]);
        }
        if (fetchedProducts.length > 0) {
            setProduct(fetchedProducts[0]);
        }
    }

    const steps = [
        <SetProspectStep orgId={orgId} prospects={prospects} onProspectSet={setProspect} currentProspect={prospect} />,
        <SetProductStep orgId={orgId} products={products} onProductSet={setProduct} currentProduct={product} />,
        <SetInfoStep orgId={orgId} onNameSet={setName} onDescriptionSet={setPrompt} currentName={name} currentDescription={prompt}/>,
        <SetReviewStep name={name} prompt={prompt} prospect={prospect} product={product}/>
    ]

    const descriptions = [
        'Select a prospect. This will be who the users will be selling the product to.',
        'Select a product. This will be what the users will need to sell to the selected prospect.',
        'Give your new scenario a name, as well as a prompt for your users to understand the context of the roleplay.',
        'Review all configurations before finalizing this scenario'
    ]

    const handleSubmit = async () => {
        const prospectId = prospect.id
        const productId = product.id
        let newScenario = {
            name, prompt, evalType, isActive, prospectId, productId, organizationId: orgId
        }
        try {
            await ScenarioService.createScenario(newScenario);
            setStep(0);
            setName('');
            setPrompt('');
            setEvalType(0);
            setIsActive(0);
            setProspect(null);
            setProduct(null);
            onSubmit();
        } catch (error) {
            console.error('Error creating new scenario ', error);
        }
    }

    return (
        <WizardModal
            title='Create a Scenario'
            descriptions={descriptions}
            steps={steps}
            onNext={() => setStep(step + 1)}
            onPrev={() => setStep(step - 1)}
            onCancel={() => onClose()}
            onSubmit={() => handleSubmit()}
        />
    )
}

export default ScenarioCreation;


// Scenario Creation steps

// 1.
const SetProspectStep = ({ orgId, prospects, onProspectSet, currentProspect = null }) => {
    const [selectedProspect, setSelectedProspect] = useState(currentProspect);

    const handleSelectedProspect = (prospect) => {
        setSelectedProspect(prospect);
        onProspectSet(prospect);
    }

    return (
        <SimpleGrid cols={3} className='prospect-step'>
            {prospects ? prospects.map((prospect, index) => (
                <div
                    key={index}
                    className={`prospect-step-card ${selectedProspect === prospect ? 'selected' : ''}`}
                    onClick={() => handleSelectedProspect(prospect)}
                >
                    <Avatar size='large' src={prospect.imageUri}/>
                    <p className='prospect-step-card-name'>{prospect.name}</p>
                    <p className='prospect-step-card-title'>{prospect.title}</p>
                    <p className='prospect-step-card-title'>{prospect.company}</p>
                </div>
            )) : null}
        </SimpleGrid>
    )
}

// 2.
const SetProductStep = ({ orgId, products, onProductSet, currentProduct = null }) => {
    const [selectedProduct, setSelectedProduct] = useState(currentProduct);

    const handlSelectedProduct = (product) => {
        setSelectedProduct(product);
        onProductSet(product);
    }

    return (
        <div className='product-step'>
            {products ? products.map((product, index) => (
                <div
                    key={index}
                    className={`product-step-card ${selectedProduct === product ? 'selected' : ''}`}
                    onClick={() => handlSelectedProduct(product)}
                >
                    <div className='product-step-card-left'>
                        <p className='product-step-card-name'>{product.name}</p>
                        <p className='product-step-card-description'>{product.description}</p>
                    </div>
                    <div className='product-step-card-right'>

                    </div>
                </div>
            )) : null}
        </div>
    )
}

// 3.
const SetInfoStep = ({ orgId, onNameSet, onDescriptionSet, currentName = '', currentDescription = '' }) => {
    const [name, setName] = useState(currentName);
    const [description, setDescription] = useState(currentDescription);

    const handleNameChange = (e) => {
        setName(e.target.value);
        if (onNameSet) onNameSet(e.target.value);
    }

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
        if (onDescriptionSet) onDescriptionSet(e.target.value);
    }

    return (
        <div className='info-step'>
            <div className='info-step-field'>
                <p>Name</p>
                <input
                    type='text'
                    value={name}
                    onChange={handleNameChange}
                    placeholder='Enter scenario name'
                />
            </div>
            <div className='info-step-field'>
                <p>Prompt</p>
                <textarea
                    value={description}
                    onChange={handleDescriptionChange}
                    placeholder='Enter a prompt for the scenario'
                />
            </div>
        </div>
    )
}

// 4.
const SetReviewStep = ({ name, prompt, evalType, isActive, prospect, product }) => {
    return (
        <div className='review-step'>
            <div className='review-step-section'>
                <p className='review-step-section-title'>Prospect</p>
                <div className='review-step-prospect-container'>
                    <div className='review-step-prospect-container-organizer'>
                        <div>
                            <p style={{fontWeight: 'bold'}}>{prospect?.name}</p>
                            <p>{prospect?.company} - {prospect?.title}</p>
                        </div>
                        <Avatar src='/icons/AgentDefault.png'/>
                    </div>
                </div>
            </div>
            
            <div className='review-step-section'>
                <p className='review-step-section-title'>Product</p>
                <div className='review-step-product-container'>
                    <p style={{fontWeight: 'bold'}}>{product?.name}</p>
                    <p>{product?.description}</p>
                </div>
            </div>
            
            <div className='review-step-section'>
                <p className='review-step-section-title'>Scenario Details</p>
                <div className='review-step-details-container'>
                    <p style={{fontWeight: 'bold'}}>{name}</p>
                    <p>{prompt}</p>
                </div>
            </div>
        </div>
    )
}

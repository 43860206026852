import React from "react";

import './UserCallRecord.css';

const UserCallRecord = ({ scenarioName, performedOn, onClick }) => {
    return (
        <div className="scenario-call-record" onClick={onClick}>
            <div className="scenario-call-record-left-content">
                <h4>{scenarioName}</h4>
            </div>
            <div className="scenario-call-record-right-content">
                <p>{new Date(performedOn).toLocaleDateString()}</p>
            </div>
        </div>
    )
}

export default UserCallRecord;
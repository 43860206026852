import { authApi } from '../api';
import Agent from '../models/Agent';
import CallRecord from '../models/CallRecord';

class AgentService {
    static async getAgentById(agentId) {

    }

    static async createAgentByScenarioId(scenarioId) {
        const response = await authApi.post(`/api/beta/agents`, {'scenarioId': scenarioId, 'difficulty': 2});
        return new Agent(response.data.id, response.data.scenarioId, response.data.messages,
            response.data.prospect, response.data.product, response.data.voice, response.data.difficulty);
    }

    static async chat(agentId, message) {
        const response = await authApi.post('/agents/chat', {'agentId': agentId, 'message': message});
        return {'message': response.data.message, 'hangup': response.data.hangUp}
    }

    static async submitCallRecord(scenarioId, agentId, callLengthInSeconds = 0) {
        const response = await authApi.post(
            `/api/scenarios/${scenarioId}/callRecords`,
            { 'scenarioId': scenarioId, 'agentId': agentId, 'isComplete': true, 'callLengthInSeconds': callLengthInSeconds }
        )
        return new CallRecord(response.data.scenarioId, response.data.agentId, 
            response.data.userEmail, response.data.isComplete, response.data.evaluationModel, response.data.createdOn);
    }
}

export default AgentService;
import React, { useState, useEffect } from 'react';

import { useDisclosure } from '@mantine/hooks';
import { Flex, Group, Modal, Popover, Stack, Text } from '@mantine/core';

import ProspectService from '../../../services/prospectService';
import BuyerRecord from '../containers/BuyerRecord';
import ButtonPrimary from '../primitives/ButtonPrimary';

import './Buyers.css';
import ProspectCreation from '../containers/ProspectCreation';

const Buyers = ({user, orgId}) => {
    const [buyers, setBuyers] = useState([]);
    const [createBuyer, setCreateBuyer] = useState(false);
    const [showCallOut, setShowCallOut] = useState(() => {
        return !localStorage.getItem('hasVisitedBuyers');
    });

    const [opened, {open, close}] = useDisclosure(false);

    const fetchBuyers = async (orgId) => {
        try {
            let fetchedBuyers = await ProspectService.getAllProspectsForOrg(orgId);
            setBuyers(fetchedBuyers);
        } catch (error) {
            console.log(error);
        }
    }

    const closeModal = () => {
        close();
        setCreateBuyer(false);
    }

    useEffect(() => {
        if (!localStorage.getItem('hasVisitedBuyers')) {
            localStorage.setItem('hasVisitedBuyers', 'true');
        }
    }, [])

    useEffect(() => {
        fetchBuyers(orgId);
    }, [orgId])

    return (
        <div className='buyers'>
            <div className='buyers-header'>
                <Group>
                    <h1>Buyers</h1>
                    <Popover width={400} position="bottom" withArrow shadow="md" backgroundColor='#056CF2' defaultOpened={showCallOut}>
                        <Popover.Target>
                            <img src="/icons/Info-filled.svg" style={{cursor:'pointer'}}/>
                        </Popover.Target>
                        <Popover.Dropdown style={{backgroundColor: '#056CF2', color:'#FFFFFF', borderColor:'#056CF2'}} p='1.4rem'>
                            <Flex direction='row' gap='1.62rem'>
                                <img src="/logo-white.svg" alt="Cognisell Logo"/>
                                <Stack gap={6}>
                                    <Text size="md" fw={700}>What are Buyers?</Text>
                                    <Text size="sm">Buyers are the personas AI agents use to simulate your sales calls.</Text>
                                </Stack>
                            </Flex>
                        </Popover.Dropdown>
                    </Popover>           
                </Group>
                <div className='new-buttons'>
                    <ButtonPrimary onClick={() => {setCreateBuyer(true); open();}}>New Buyer</ButtonPrimary>
                </div>
            </div>
            <div className='buyers-body'>
                <div className='buyer-table-header'>
                    <span className='buyer-name-header'>Name</span>
                </div>
                <div className='buyer-table-rows'>
                    {buyers ? buyers.map((buyer, index) => (
                        <BuyerRecord
                            name={buyer.name}
                            company={buyer.company}
                            title={buyer.title}
                            imageUri={buyer.imageUri}
                        />
                    )) : null}
                </div>
            </div>
            <div className='scenario-modals'>
                <Modal opened={opened} onClose={closeModal} withCloseButton={false} centered size='xl' padding={0}>
                    {createBuyer &&
                        <ProspectCreation
                            orgId={orgId}
                            onClose={() => {setCreateBuyer(false); closeModal()}} />}
                </Modal>
            </div>
        </div>
    )
}

export default Buyers;
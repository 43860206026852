import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { DEMO_ORG } from '../../utils/constants';
import { useAuth } from '../../context/authContext';
import UserService from '../../services/userService';

import Sidebar from '../modules/containers/Sidebar';
import Scenarios from '../modules/views/Scenarios';
import Buyers from '../modules/views/Buyers';
import Products from '../modules/views/Products';
import Users from '../modules/views/Users';
import LoadingSpinner from '../LoadingSpinner';

import './Dashboard.css';
import MyRecordings from '../modules/views/MyRecordings';

const Dashboard = () => {
  const { isLoggedIn, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // Helper function to get orgId from the URL's query parameters
  const getOrgIdFromUrl = () => {
    const params = new URLSearchParams(location.search);
    return params.get('orgId');
  };

  const [user, setUser] = useState(null);
  const [currentUserOrg, setCurrentUserOrg] = useState(null);
  const [orgId, setOrgId] = useState(getOrgIdFromUrl());
  const [userOrgs, setUserOrgs] = useState([]);
  const [activeComponent, setActiveComponent] = useState("Scenarios");

  const handleOrgChange = (newOrgId) => {
    const selectedOrg = userOrgs.find(org => org.orgId === newOrgId);
    if (selectedOrg) {
        setOrgId(newOrgId);
        setCurrentUserOrg(selectedOrg);
        navigate(`?orgId=${newOrgId}`); // Update the URL's query parameters
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      UserService.getAllOrgsForUser()
        .then(orgs => {
          if (orgs === null) {
            logout();
            navigate('/login');
          }
          setUserOrgs(orgs);

          const selectedOrgId = getOrgIdFromUrl() || orgs[0]?.orgId || DEMO_ORG;
          const selectedOrg = orgs.find(org => org.orgId === selectedOrgId);

          setOrgId(selectedOrgId);
          setCurrentUserOrg(selectedOrg);
          return UserService.getCurrentUserForOrg(selectedOrg.orgId)
        })
        .then(setUser)
        .catch(console.error);
    } else {
      navigate("/login")
    }
  }, [isLoggedIn]);

  if (!user) {
    return (
      <div className='dashboard-loading'>
        <LoadingSpinner />
      </div>    
    );
  }

  return (
    <div className='dashboard'>
      <Sidebar user={user} userOrg={currentUserOrg} allUserOrgs={userOrgs} onActiveOptionChange={setActiveComponent} onOrgChange={handleOrgChange} />
      {activeComponent === "Scenarios" && <Scenarios user={user} orgId={currentUserOrg.orgId} />}
      {activeComponent === "Buyers" && <Buyers user={user} orgId={currentUserOrg.orgId} />}
      {activeComponent === "Products" && <Products user={user} orgId={currentUserOrg.orgId} />}
      {activeComponent === "Users" && <Users user={user} orgId={currentUserOrg.orgId} />}
      {activeComponent === "Recordings" && <MyRecordings user={user} orgId={currentUserOrg.orgId} />}
    </div>    
  );
};

export default Dashboard;

import React from 'react';
import PropTypes from 'prop-types';
import './ButtonPrimary.css';

const ButtonPrimary = ({ children, onClick, type, disabled }) => {
  return (
    <button className="button-primary" onClick={onClick} type={type} disabled={disabled} >
      {children}
    </button>
  );
};

ButtonPrimary.propTypes = {
  /*children: PropTypes.node.isRequired,*/ // maybe not needed...
  onClick: PropTypes.func
};

export default ButtonPrimary;

import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/authContext';
import { useNavigate, useLocation } from 'react-router-dom';
import './PasswordReset.css';

const PasswordReset = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    const { resetPassword } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setError("Passwords do not match");
            return;
        }

        try {
            await resetPassword(email, token, password);
            alert("Password reset complete");
            navigate('/login');
        } catch (error) {
            setError('Failed to reset password. Please submit a new password reset request and try again.');
            console.error(error);
        }
    };

    return (
        <div className='password-reset-page'>
            <div className="password-reset">
                <div className='password-reset-header'>
                    <h2>Reset your password</h2>
                </div>
                <div className='password-reset-body'>
                    <form className='password-reset-form' onSubmit={handleSubmit}>
                        {error && <div className='reset-error'><p>{error}</p></div>}
                        <input
                            type="text"
                            placeholder="Confirm your email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                        <input
                            type="password"
                            placeholder="new password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                        <input
                            type="password"
                            placeholder="confirm password"
                            value={confirmPassword}
                            onChange={e => setConfirmPassword(e.target.value)}
                        />
                        <button type="submit">Reset Password</button>
                    </form>
                </div>
            </div>
        </div>      
    )
}
export default PasswordReset
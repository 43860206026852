import React from 'react';
import { useParams } from 'react-router-dom';

// Import of test components
import Avatar from '../primitives/Avatar';
import ButtonPrimary from '../primitives/ButtonPrimary';

const COMPONENTS = {
  Avatar,
  ButtonPrimary
  // other components...
};

function DevelopmentPage() {
  let { componentName } = useParams();

  const ComponentToRender = COMPONENTS[componentName];

  if (!ComponentToRender) {
    return <div>Component not found!</div>;
  }

  return (
    <div>
      <h1>Developing: {componentName}</h1>
      <ButtonPrimary>Some Text</ButtonPrimary>
    </div>
  );
}

export default DevelopmentPage;

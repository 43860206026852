import { authApi } from '../api';
import Scenario from '../models/Scenario';
import CallRecord from '../models/CallRecord';

class ScenarioService {
  static async getAllScenariosForOrg(orgId) {
    try {
      const response = await authApi.get(`/api/scenarios/orgs/${orgId}`);
      return response.data.map(scenario => new Scenario(scenario.id, scenario.organizationId,
        scenario.prospectId, scenario.productId, scenario.name, scenario.prompt, scenario.type, scenario.isActive));
    } catch (error) {
      console.error('Error fetching scenarios for org', error);
      throw error;
    }
  }

  static async getScenario(scenarioId) {
    try {
        const response = await authApi.get(`/api/scenarios/${scenarioId}`);
        return new Scenario(response.data.id, response.data.organizationId,
            response.data.prospectId, response.data.productId, response.data.name, response.data.prompt, response.data.type, response.data.isActive);
    } catch (error) {
        console.error('Error fetching scenario', error);
        throw error;
      }
  }

  static async createScenario(newScenario) {
    try {
      const response = await authApi.post(`/api/scenarios`, newScenario);
      return new Scenario(response.data.id, response.data.organizationId,
        response.data.prospectId, response.data.productId, response.data.name, response.data.prompt, response.data.type, response.data.isActive);
    } catch (error) {
      console.error('Error creating new scenario', error);
      throw error;
    }
  }

  static async updateScenario(scenario) {
    try {
      const response = await authApi.put(`api/scenarios`, scenario);
    } catch (error) {
      console.error('Error updating scenario', error);
      throw error;
    }
  }

  static async deleteScenario(scenarioId, orgId) {
    try {
      await authApi.delete(`/api/scenarios/${scenarioId}/orgs/${orgId}`);
    } catch (error) {
      console.error('Error deleting scenario', error);
      throw error;
    }
  }

  static async getCallRecordsForOrg(orgId) {
    try {
      const response = await authApi.get(`/api/scenarios/orgs/${orgId}/callRecords`);
      return response.data.map(record => new CallRecord(record.scenarioId, record.agentId, 
        record.userEmail, record.userFirstName, record.userLastName, record.isComplete, record.callLengthInSeconds, record.evaluationModel, record.createdOn));
    } catch (error) {
      console.error(`Error in getting call records for org`, error)
    }
  }

  static async getCallRecordsForScenario(scenarioId) {
    try {
        const response = await authApi.get(`/api/scenarios/${scenarioId}/callRecords`);
        return response.data.map(record => new CallRecord(record.scenarioId, record.agentId, 
            record.userEmail, record.userFirstName, record.userLastName, record.isComplete, record.callLengthInSeconds, record.evaluationModel, record.createdOn));
    } catch (error) {
        console.error('Error fetching call records for scenario');
        throw error;
    }
  }

  static async getCallRecord(scenarioId, callRecordId) {
    try {
      const response = await authApi.get(`/api/scenarios/${scenarioId}/callRecords/${callRecordId}`);
      return new CallRecord(response.data.scenarioId, response.data.agentId, response.data.userEmail, response.data.userFirstName,
        response.data.userLastName, response.data.isComplete, response.data.callLengthInSeconds, response.data.evaluationModel, response.data.createdOn);
    } catch (error) {
        console.error('Error fetching call record');
        throw error;
    }
  }
}

export default ScenarioService;

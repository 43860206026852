import React, { useState, useEffect } from 'react';  // Ensure you've imported useState and useEffect
import { useNavigate } from 'react-router-dom';

import UserCallRecord from './UserCallRecord';

import './UserModal.css';
import { ActionIcon } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';

const UserModal = ({user, scenarios, callRecords, onClose }) => {
    const [userCallRecords, setUserCallRecords] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const mappedRecords = callRecords.map(record => {
            const matchedScenario = scenarios.find(scenario => scenario.id === record.scenarioId);
            return {
                ...record,
                scenario: matchedScenario
            };
        });
        setUserCallRecords(mappedRecords);
    }, [callRecords, scenarios]);

    return (
        <div className='scenario-modal'>
            <div className='header-wrapper'>
                <ActionIcon
                    variant='transparent' color='black' onClick={onClose}>
                    <IconArrowLeft/>
                </ActionIcon>
                <div className='scenario-header'>
                    <div className='scenario-header-top'>
                        <h1>{user.firstName} {user.lastName}</h1>
                    </div>
                </div>
            </div> 
            <div className='scenario-body-container'>
                <div className='scenario-body'>
                    <div className='user-records'>
                        {userCallRecords.map(record => (
                            <UserCallRecord
                                scenarioName={record.scenario.name}
                                performedOn={record.createdOn}
                                onClick={x => navigate(`/scenarios/${record.scenario.id}/replay/${record.agentId}`)}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserModal;

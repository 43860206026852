import React, { useState, useEffect } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { Flex, Group, Modal, Popover, Stack, Text } from '@mantine/core';
import UserService from '../../../services/userService';

import UserModal from '../containers/UserModal';
import ScenarioService from '../../../services/scenarioService';
import UserRecord from '../containers/UserRecord';

import './Users.css';

const Users = ({ user, orgId }) => {
    const [orgUsers, setOrgUsers] = useState([]);
    const [scenarios, setScenarios] = useState([]);
    const [callRecords, setCallRecords] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showCallout, setShowCallOut] = useState(() => {
        const hasVisitedUsers = localStorage.getItem('hasVisitedUsers');
        return !hasVisitedUsers;
    });

    const [opened, { open, close }] = useDisclosure(false);

    const fetchOrgUsers = async (orgId) => {
        try {
            let fetchedUsers = await UserService.getAllUsersForOrg(orgId);
            setOrgUsers(fetchedUsers);
        } catch (error) {
            console.log(error);
        }
    }

    const fetchScenarios = async(orgId) => {
        try {
            let fetchedScenarios = await ScenarioService.getAllScenariosForOrg(orgId);
            setScenarios(fetchedScenarios);
        } catch (error) {
            console.log(error);
        }
    }

    const fetchCallRecords = async (orgId) => {
        try {
            let fetchedCallRecords = await ScenarioService.getCallRecordsForOrg(orgId);
            setCallRecords(fetchedCallRecords);
        } catch (error) {
            console.log(error);
        }
    }

    const closeModal = () => {
        close();
        setSelectedUser(null);
    }

    useEffect(() => {
        if (!localStorage.getItem('hasVisitedUsers')) {
            localStorage.setItem('hasVisitedUsers', 'true');
        }
    }, []);

    useEffect(() => {
        fetchOrgUsers(orgId);
        fetchScenarios(orgId);
        fetchCallRecords(orgId);
    }, [orgId])

    useEffect(() => {

        if (selectedUser == null)
            close();
        else {
            open();
        }

    }, [selectedUser])

    return (
        <div className="users">
            <div className='users-header'>
                <Group>
                    <h1>Users</h1>
                    <Popover width={400} position="bottom" withArrow shadow="md" backgroundColor='#056CF2' defaultOpened={showCallout}>
                        <Popover.Target>
                            <img src="/icons/Info-filled.svg" style={{cursor:'pointer'}}/>
                        </Popover.Target>
                        <Popover.Dropdown style={{backgroundColor: '#056CF2', color:'#FFFFFF', borderColor:'#056CF2'}} p='1.4rem'>
                            <Flex direction='row' gap='1.62rem'>
                                <img src="/logo-white.svg" alt="Cognisell Logo"/>
                                <Stack gap={6}>
                                    <Text size="md" fw={700}>What are Users?</Text>
                                    <Text size="sm">These are the associated accounts with your organization. Regular users only have access to scenarios enabled to them, as well as their previous call performances. Admins have the ability to create new scenarios and review the performance of other users within their organization.</Text>
                                </Stack>
                            </Flex>
                        </Popover.Dropdown>
                    </Popover>
                </Group>
                <div className='new-buttons'>
                    {/* we have no buttons to add here at the moment for user actions*/}
                </div>
            </div>
            <div className='users-body'>
                <div className="users-table-header">
                    <span className="users-name-header">Name</span>
                    <span className='users-role-header'>Role</span>
                </div>
                <div className='users-table-rows'>
                    {orgUsers ? orgUsers.map((orgUser, index) => (
                        <UserRecord
                            key={orgUser.email}
                            firstName={orgUser.firstName}
                            lastName={orgUser.lastName}
                            email={orgUser.email}
                            globalId={orgUser.globalId}
                            role={orgUser.role}
                            onClick={() => setSelectedUser(orgUser)}
                        />
                    )) : null}
                </div>
            </div>
            <div className='scenario-modals'>
                <Modal opened={opened} onClose={closeModal} withCloseButton={false} centered size='xl' padding={0}>
                    {selectedUser && 
                        <UserModal 
                            user={selectedUser} 
                            scenarios={scenarios} 
                            callRecords={callRecords.filter(record => record.userEmail === selectedUser.email)}
                            onClose={() => setSelectedUser(null)}
                        />
                    }
                </Modal>
            </div>
        </div>
    )
}

export default Users;
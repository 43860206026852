///
// This context provider makes auth state and its functions available throught the application. We wrap everything else around this
// auth context.
///

import { createContext, useContext, useState, useEffect } from 'react';
import * as authService from '../services/authService';

const AuthContext = createContext(null);

export function AuthProvider({ children }) {
    const [isLoggedIn, setIsLoggedIn] = useState(authService.isLoggedIn());

    useEffect(() => {
        authService.initialize();
    }, []);

    // TODO: handle cases where login and register fail.  We don't want to set some logged in state to be true if thats the case
    const login = async (email, password) => {
        await authService.login(email, password);
        setIsLoggedIn(true);
    }

    const register = async (email, password, registerCode, firstName, lastName) => {
        // TODO: Add support for the registration code
        await authService.register(email, password, registerCode, firstName, lastName);
        setIsLoggedIn(true);
    }

    const requestPasswordReset = async (email) => {
        return await authService.requestPasswordReset(email);
    }

    const resetPassword = async (email, token, newPassword) => {
        return await authService.resetPassword(email, token, newPassword);
    }

    const logout = () => {
        authService.logout();
        setIsLoggedIn(false);
    }

    return (
        <AuthContext.Provider value={{ isLoggedIn, login, register, requestPasswordReset, resetPassword, logout }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}
import React, { useState, useEffect } from 'react';

import ProductService from '../../../services/productService';
import Avatar from '../primitives/Avatar';

import './ProductCreation.css';
import WizardModal from './WizardModal';

const ProductCreation = ({ orgId, onClose, onSubmit }) => {
    const [step, setStep] = useState(0);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const steps = [
        <SetInfoStep onNameSet={setName} onDescriptionSet={setDescription} currentName={name} currentDescription={description} />
    ]

    const descriptions = [
        'Provide the brief details about a product that will be sold.'
    ]

    const handleSubmit = async () => {
        let newProduct = { name, description, orgId }
        try {
            await ProductService.createProduct(newProduct);
            setName('');
            setDescription('');
            onClose();
        } catch (error) {
            console.error('Error creating new product ', error);
        }
    }

    return (
        <WizardModal
            title='Create a product'
            descriptions={descriptions}
            steps={steps}
            onNext={() => setStep(step + 1)}
            onPrev={() => setStep(step - 1)}
            onCancel={() => onClose()}
            onSubmit={() => handleSubmit()}
        />
    )
}

export default ProductCreation;

// Product Creation Steps

// 1.
const SetInfoStep = ({ onNameSet, onDescriptionSet, currentName = '', currentDescription = '' }) => {
    const [name, setName] = useState(currentName);
    const [description, setDescription] = useState(currentDescription);

    const handleNameChange = (e) => {
        setName(e.target.value);
        if (onNameSet) onNameSet(e.target.value);
    }

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
        if (onDescriptionSet) onDescriptionSet(e.target.value);
    }

    return (
        <div className='info-step'>
            <div className='info-step-field'>
                <p>Name</p>
                <input
                    type='text'
                    value={name}
                    onChange={handleNameChange}
                    placeholder='Enter product name'
                />
            </div>
            <div className='info-step-field'>
                <p>Description</p>
                <textarea
                    value={description}
                    onChange={handleDescriptionChange}
                    placeholder='Enter a brief description for the product'
                />
            </div>
        </div>
    )
}
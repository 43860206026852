import React from 'react';
import './Evaluation.css';

const Evaluation = ({ callRecord }) => {
    const getCategoryFromScore = (score) => {
        if (score >= 85) return 'Excellent';
        if (score >= 70) return 'Good';
        if (score >= 55) return 'Fair';
        return 'Lacking';
    }

    return (
        <div className="evaluation">
            <h3>Evaluation results</h3>
            <div className="evaluation-body">
                {callRecord.evaluationModel && Object.entries(callRecord.evaluationModel).map(([key, value]) => {
                    const category = value.score ? getCategoryFromScore(value.score) : null;
                    
                    return (
                        <div className="evaluation-section" key={key}>
                            
                            <div className='evaluation-section-body'>
                                <h2>{key}</h2>
                                <ul className='evaluation-notes'>
                                    {value.notes.map((note, index) => (
                                        <li className='note' key={index}>
                                            {note}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div></div>
                            {category && 
                                <div className={`score-${category}`}>
                                    <p>{category}</p>
                                </div>
                            }
                        </div>
                    );
                })}
            </div>
        </div>
    )
};

export default Evaluation;

import React from 'react';
import ButtonPrimary from '../primitives/ButtonPrimary';
import ButtonSecondary from '../primitives/ButtonSecondary';

import './WizardModal.css';

const WizardHeader = ({ title, description }) => (
  <div className='wizard-header'>
    <h2>{title}</h2>
    <p>{description}</p>
  </div>
);

const WizardFooter = ({ steps, setCurrentStep, currentStep, onNext, onPrev, onCancel, onSubmit }) => (
  <div className='wizard-footer'>
    <div className='wizard-footer-steps'>
      {steps.map((step, index) => (
        <span
            key={index}
            style={{ color: index === currentStep ? '#056CF2' : 'gray', cursor: 'pointer', margin: '0.3rem', fontSize: '0.75rem' }}
            onClick={() => {
                if (index !== currentStep) {
                    setCurrentStep(index)
                }
            }}
        >
          ●
        </span>
      ))}
    </div>
    <div className='wizard-footer-buttons'>
        <ButtonPrimary
            children={currentStep === steps.length - 1 ? 'Create' : 'Next'}
            onClick={currentStep === steps.length - 1 ? onSubmit : onNext}
        />
        <ButtonSecondary
            children={'Cancel'}
            onClick={onCancel}
        />
    </div>
  </div>
);

const WizardModal = ({ steps, title, descriptions, onNext, onPrev, onCancel, onSubmit }) => {
  const [currentStep, setCurrentStep] = React.useState(0);

  const next = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
      onNext();
    }
  };

  const prev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      onPrev();
    }
  };

  return (
    <div className='wizard-modal'>
      <WizardHeader 
        title={title} 
        description={descriptions[currentStep]} 
      />
      {steps[currentStep]}
      <WizardFooter
        steps={steps}
        setCurrentStep={setCurrentStep}
        currentStep={currentStep}
        onNext={next}
        onPrev={prev}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default WizardModal;

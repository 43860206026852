import React, { useEffect, useState } from "react";

import ScenarioService from "../../../services/scenarioService";
import UserCallRecord from "../containers/UserCallRecord";

import './MyRecordings.css';
import LoadingSpinner from "../../LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { Flex, Group, Popover, Stack, Text } from "@mantine/core";

const MyRecordings = ({ user, orgId }) => {
    const [scenarios, setScenarios] = useState([]);
    const [callRecords, setCallRecords] = useState([]);
    const [mappedRecords, setMappedRecords] = useState([]);
    const [showCallout, setShowCallOut] = useState(() => {
        const hasVisitedRecordings = localStorage.getItem('hasVisitedRecordings');
        return !hasVisitedRecordings;
    });
    const navigate = useNavigate();

    const fetchScenarios = async (orgId) => {
        try {
            let fetchedScenarios = await ScenarioService.getAllScenariosForOrg(orgId);
            setScenarios(fetchedScenarios);
            return fetchedScenarios;
        } catch (error) {
            console.log(error);
        }
    }
    
    const fetchCallRecords = async(orgId) => {
        try {
            let fetchedCallRecords = await ScenarioService.getCallRecordsForOrg(orgId);
            setCallRecords(fetchedCallRecords);
            return fetchedCallRecords;
        } catch (error) {
            console.log(error);
        }
    }
    
    useEffect(() => {
        if (!localStorage.getItem('hasVisitedRecordings')) {
            localStorage.setItem('hasVisitedRecordings', 'true');
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const fetchedCallRecords = await fetchCallRecords(orgId);
            const fetchedScenarios = await fetchScenarios(orgId);
        
            const mappingRecords = fetchedCallRecords.map(record => {
                const matchedScenario = fetchedScenarios.find(scenario => scenario.id === record.scenarioId);
                return {
                    ...record,
                    scenario: matchedScenario
                };
            });
                
            setMappedRecords(mappingRecords);
        }
        
        fetchData();
    }, [orgId]);

    return (
        <div className='users'>
            <div className='users-header'>
                <Group>
                    <h1>My Recordings</h1>
                    <Popover width={400} position="bottom" withArrow shadow="md" backgroundColor='#056CF2' defaultOpened={showCallout}>
                        <Popover.Target>
                            <img src="/icons/Info-filled.svg" style={{cursor:'pointer'}}/>
                        </Popover.Target>
                        <Popover.Dropdown style={{backgroundColor: '#056CF2', color:'#FFFFFF', borderColor:'#056CF2'}} p='1.4rem'>
                            <Flex direction='row' gap='1.62rem'>
                                <img src="/logo-white.svg" alt="Cognisell Logo"/>
                                <Stack gap={6}>
                                    <Text size="md" fw={700}>What are My Recordings?</Text>
                                    <Text size="sm">These are your previous call records where you can re-visit your performance and evaluation.</Text>
                                </Stack>
                            </Flex>
                        </Popover.Dropdown>
                    </Popover>
                </Group>
                <div className="new-buttons">
                    {/* nothing to add here currently */}
                </div>
            </div>
            <div className="users-body">
                <div className="users-table-header">
                    <span className="users-name-header">Call</span>
                    <span className='users-role-header'>Date</span>
                </div>
                <div className='myrecordings-table-rows'>
                    {mappedRecords ? mappedRecords.map((record, index) => (
                        <UserCallRecord
                            key={index}
                            scenarioName={record.scenario.name}
                            performedOn={record.createdOn}
                            onClick={x => navigate(`/scenarios/${record.scenario.id}/replay/${record.agentId}`)}
                        />
                    )) : <LoadingSpinner />}
                </div>
            </div>
        </div>
    )
}

export default MyRecordings;
import React from 'react';
import Avatar from '../primitives/Avatar';

import './BuyerRecord.css';

const BuyerRecord = ({name, company, title, imageUri, onClick}) => {
    return (
        <div className='buyer-record' onClick={onClick}>
            <div className='buyer-record-left-content'>
                <Avatar src={imageUri} size='medium' />
                <div className='buyer-info'>
                    <h4 className='main'>{name}</h4>
                    <p className='sub'>{title}, {company}</p>
                </div>
            </div>
            <div className='buyer-record-right-content'>

            </div>
        </div> 
    )
}

export default BuyerRecord;

import axios from 'axios';

const api = axios.create({
    baseURL: 'https://cognisell-services.azurewebsites.net',
});

const authApi = axios.create({
    baseURL: 'https://cognisell-services.azurewebsites.net',
});
const token = localStorage.getItem('authToken');
if (token) {
    authApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export { api, authApi }; 
import React from 'react';
import Avatar from '../primitives/Avatar';

import './ScenarioCallRecord.css';

const ScenarioCallRecord = ({ firstName, lastName, email, isComplete, callLength, performedOn, onClick }) => {
    // Format the time as "mm:ss"
    const minutes = Math.floor(callLength / 60);
    const seconds = callLength % 60;
    const formattedTime = `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;

    return (
        <div className="scenario-call-record" onClick={onClick}>
            <div className="scenario-call-record-left-content">
                <Avatar size="medium" />
                <div className='user-info'>
                    <h4>{firstName} {lastName}</h4>
                    <p>{email}</p>
                </div>
            </div>
            <div className='scenario-call-record-right-content'>
                <p>{callLength !== 0 ? formattedTime : "Uknown"}</p>
                <p>{performedOn}</p>
            </div>
        </div>
    )
}

export default ScenarioCallRecord;
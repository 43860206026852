import { authApi } from '../api';
import OrgUser from '../models/OrgUser';
import UserOrg from '../models/UserOrg';
import Group from '../models/Group';

class UserService {
  static async getCurrentUserForOrg(orgId) {
    try {
      const response = await authApi.get(`/api/orgs/${orgId}/users/current`);
      const { email, firstName, lastName, globalId, role } = response.data;
      return new OrgUser(email, firstName, lastName, globalId, role);
    } catch (error) {
      throw error;
    }
  }

  static getUserProfilePicture(userGlobalId) {
    return authApi.getUri() + `/api/users/profile-picture/${userGlobalId}`;
  }

  static async getAllOrgsForUser() {
    try {
      // this api path is funky, but it gets all the orgs (in UserOrg models) for the signed in user
      const response = await authApi.get('/api/orgs/users');
      return response.data.map(org => new UserOrg(org.userEmail, org.orgId, org.orgName));
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          // a forbidden response means we are no longer authenticated. Let the caller know so they
          // can handle any logout procedure of removing their token.
          return null;
        }
      }
    }
  }

  static async getAllUsersForOrg(orgId) {
    try {
      const response = await authApi.get(`/api/orgs/${orgId}/users`);
      return response.data.map(user => {
        let role;
        switch (user.role) {
          case 0:
            role = "Student";
            break;
          case 2:
            role = "Admin";
            break;
          default:
            role = "Not Specified";
            break;
        }
        return new OrgUser(user.email, user.firstName, user.lastName, user.globalId, role)
      });
    } catch (error) {
      throw error;
    }
  }

  static async getAllGroupsForOrg(orgId) {
    try {
      const response = await authApi.get(`/api/orgs/${orgId}/groups`);
      return response.data.map(group => new Group(group.organizationId, group.id, group.name, group.description, group.createdBy, group.createdOn, group.lastUpdatedBy, group.lastUpdatedOn));
    } catch (error) {
      throw error;
    }
  }
}

export default UserService;
